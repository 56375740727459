<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <div class="row">
    <div class="col">
      <h3>
        <a class="text-primary" [routerLink]="['../']"><u>My Positions</u> </a> -> {{ position.symbol }} (<span [ngClass]="{ 'text-danger text-bold': position.lastTrade < position.previousClose, 'text-success text-bold': position.lastTrade > position.previousClose }">{{ position.lastTrade | currency }}</span
        >)
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h5>Earnings Date: {{ position.earnignsDate | date: 'shortDate' }} ({{ position.weeksUntilEarnings }} weeks)</h5>
      <h5>Contracts: {{ position.qty }}</h5>
      <h5>
        Margin: <strong>{{ position.margin | currency }}</strong>
      </h5>
    </div>
    <div class="col text-right">
      <h5>
        Total P/L:
        <strong
          ><span [ngClass]="{ 'text-danger text-bold': position.totalProfitLoss <= 0, 'text-success text-bold': position.totalProfitLoss > 0 }">{{ position.totalProfitLoss | currency | minusSignToParens }}</span></strong
        >
      </h5>
      <h5>
        Return on Margin:
        <strong
          ><span [ngClass]="{ 'text-danger text-bold': position.totalProfitLoss <= 0, 'text-success text-bold': position.totalProfitLoss > 0 }">{{ position.totalProfitLoss / position.margin | percent }}</span></strong
        >
      </h5>
    </div>
  </div>
  <div class="card p-0">
    <div class="card-header text-center p-0"><h4>Long Puts</h4></div>
    <div class="card-body p-0">
      <table class="table table-hover">
        <thead class="bg-light">
          <tr>
            <th>Date Purchased</th>
            <th>Expiration</th>
            <th>Price at Trade</th>
            <th>Strike</th>
            <th>Premium</th>
            <th>Exit</th>
            <th class="text-center">Premium Earned</th>
            <th class="text-center">Profit/Loss</th>
            <th class="text-center">Cumulative</th>
            <th class="text-center">%</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of longOrders; let idx = index">
            <td>{{ order.transactionDate | date: 'shortDate' }}</td>
            <td>{{ order.expiration | date: 'shortDate' }}</td>
            <td>{{ order.priceAtTrade | currency }}</td>
            <td>
              <strong>{{ order.strike | currency }}</strong>
            </td>
            <td>{{ order.premium | currency }}</td>
            <td>{{ LongExitPremium(idx, order.optionSymbol) | currency }} <i *ngIf="HasExitedLong(idx, order.optionSymbol)" data-toggle="tooltip" data-placement="top" title="{{ LongExitDate(idx, order.optionSymbol) | date: 'shortDate' }}" class="cursor-pointer text-success fas fa-check"></i></td>
            <td class="text-center bg-light">{{ GetEarningsLong(idx, order) | currency | minusSignToParens }}</td>
            <td class="text-center bg-light">{{ GetPLLong(idx, order) | currency | minusSignToParens }}</td>
            <td class="text-center bg-light">{{ GetLongCumulative(idx, order) | currency | minusSignToParens }}</td>
            <td class="text-center bg-light">{{ GetPercentLong(idx, order) | percent: '1.2' }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="8"></th>
            <th class="text-center">{{ position.longProfitLoss | currency | minusSignToParens }}</th>
            <th class="text-center"></th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="card-header text-center p-0"><h4>Short Puts</h4></div>
    <div class="card-body p-0">
      <table class="table table-hover">
        <thead class="bg-light">
          <tr>
            <th>Date Sold</th>
            <th>Expiration</th>
            <th>Price at Trade</th>
            <th>Strike</th>
            <th>Premium</th>
            <th>Exit</th>
            <th class="text-center">Premium Earned</th>
            <th class="text-center">Profit/Loss</th>
            <th class="text-center">Cumulative</th>
            <th class="text-center">%</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of shortOrders; let idx = index">
            <td>{{ order.transactionDate | date: 'shortDate' }}</td>
            <td>{{ order.expiration | date: 'shortDate' }}</td>
            <td>{{ order.priceAtTrade | currency }}</td>
            <td>
              <strong>{{ order.strike | currency }}</strong>
            </td>
            <td>{{ order.premium | currency }}</td>
            <td>{{ ShortExitPremium(idx, order.optionSymbol) | currency }} <i *ngIf="HasExitedShort(idx, order.optionSymbol)" data-toggle="tooltip" data-placement="top" title="{{ ShortExitDate(idx, order.optionSymbol) | date: 'shortDate' }}" class="cursor-pointer text-success fas fa-check"></i></td>
            <td class="text-center bg-light">{{ GetEarningsShort(idx, order) | currency | minusSignToParens }}</td>
            <td class="text-center bg-light">{{ GetPLShort(idx, order) | currency | minusSignToParens }}</td>
            <td class="text-center bg-light">{{ GetShortCumulative(idx, order) | currency | minusSignToParens }}</td>
            <td class="text-center bg-light">{{ GetPercentShort(idx, order) | percent: '1.2' }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="8"></th>
            <th class="text-center">{{ position.shortProfitLoss | currency | minusSignToParens }}</th>
            <th class="text-center"></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToasterService } from '../_services/toaster.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, private toastr: ToasterService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('.json')) {
      return next.handle(request);
    }

    if (request.url.indexOf('channel.media.azure.net') >= 0) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (request.url.endsWith('api/users/authenticate')) {
          const error = err.error!.message || err.statusText;
          return throwError(error);
        }



        if (err.status == 403) {
          this.toastr.error('Not Authorized', 'You are not authorized to perform the selected action.');
          const error = (err.error && err.error.message) || err.statusText;
          return throwError(error);
        }

        if (err.status >= 500 && err.status <= 599) {
          this.toastr.error('Unexpected Error', 'An unexpected error has occured. Please try again.');
        }

        // const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MoneyPressCandidateResult, MoneyPressPositionViewModel } from 'src/app/_models/generatedModels';
import { ETradeService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-mp-checkup',
  templateUrl: 'mp-checkup.component.html',
})
export class MpCheckupComponent implements OnInit {
  initialized = false;
  searching = false;
  firstSearch = false;
  symbol: string;
  currentData: MoneyPressCandidateResult;
  constructor(private etrade: ETradeService, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("MP Checkup");
    this.initialized = true;
  }

  checkStock() {
    this.searching = true;
    this.etrade.checkMoneyPressCandidate(this.symbol).subscribe((result) => {
      this.currentData = result;
      this.firstSearch = true;
      this.searching = false;
    });
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <div class="row">
    <div class="col">
      <h3>
        <a class="text-primary" [routerLink]="['../../']"><u>My Positions</u> </a> -> {{ position.symbol }} (<span [ngClass]="{ 'text-danger text-bold': position.lastTrade < position.previousClose, 'text-success text-bold': position.lastTrade > position.previousClose }">{{ position.lastTrade | currency }}</span
        >)
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>{{ position.symbol }} History</h3>
      <kendo-grid [kendoGridBinding]="history" kendoGridSelectBy="id" [pageSize]="90" [pageable]="false" [sortable]="true" [groupable]="false" [reorderable]="true" [resizable]="true" [columnMenu]="{ filter: true }">
        <kendo-grid-column-group title="General Market" [columnMenu]="false">
          <kendo-grid-column [columnMenu]="false" field="date" title="Date" [width]="90">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name">{{ dataItem.date | date: 'shortDate' }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [columnMenu]="false" field="dow" title="Short PL" [width]="80" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.shortClose < 0, 'text-success text-bold': dataItem.shortClose > 0 }">{{ dataItem.shortClose | currency | minusSignToParens }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [columnMenu]="false" field="nasdaq" title="Long PL" [width]="90" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.longClose < 0, 'text-success text-bold': dataItem.longClose > 0 }">{{ dataItem.longClose | currency | minusSignToParens }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [columnMenu]="false" field="sP500" title="Total PL" [width]="90" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.close < 0, 'text-success text-bold': dataItem.close > 0 }">{{ dataItem.close | currency | minusSignToParens }}</div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-column-group title="Analysis" [columnMenu]="false">
          <kendo-grid-column [columnMenu]="false" field="iWM" title="Acc/Dist" [width]="70" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name" [ngClass]="dataItem.accDist">{{ dataItem.accDist }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [columnMenu]="false" field="iWM" title="Candidate" [width]="70" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.stockInvestCandidate == 'Sell' || dataItem.stockInvestCandidate == 'Strong Sell', 'text-success text-bold': dataItem.stockInvestCandidate == 'Buy' || dataItem.stockInvestCandidate == 'Strong Buy', 'text-warning text-bold': dataItem.stockInvestCandidate == 'Hold' }">{{ dataItem.stockInvestCandidate }}</div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [columnMenu]="false" field="iWM" title="Zacks Rank" [width]="70" filter="numeric">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.zacksRank >= 4, 'text-success text-bold': dataItem.zacksRank <= 2, 'text-warning text-bold': dataItem.zacksRank == 3 }">{{ dataItem.zacksRank }}</div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>
      </kendo-grid>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { LongRollChoiceViewModel, MoneyPressPositionViewModel } from 'src/app/_models/generatedModels';
import { ETradeService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-short-chains',
  templateUrl: 'short-chains.component.html',
})
export class ShortChainsComponent implements OnInit, OnDestroy {
  initialized = false;
  private routeSubscription: Subscription;
  symbol: number;
  fetcher: any;
  chains: LongRollChoiceViewModel[];
  position: MoneyPressPositionViewModel;
  constructor(private route: ActivatedRoute, private etradeService: ETradeService, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Short Chains");
    this.routeSubscription = this.route.paramMap.subscribe((param) => {
      this.symbol = parseInt(param.get('id'));
      this.fetchData();
      this.fetcher = interval(5000).subscribe((val) => {
        this.fetchData();
      });
    });
  }

  fetchData() {
      this.etradeService.getPosition(this.symbol).subscribe((position) => {
        this.position = position;
        this.etradeService.getOptionChainsForShort(this.symbol).subscribe((chains) => {
          this.chains = chains;
          this.initialized = true;
        });
      });

  }

  ngOnDestroy(): void {
    this.fetcher.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}

import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { interval } from 'rxjs';
import { MinusSignToParens } from 'src/app/_helpers/extensions.module';
import { MoneyPressPositionViewModel } from 'src/app/_models/generatedModels';
import { ETradeService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-my-positions',
  templateUrl: 'my-positions.component.html',
})
export class MyPositionsComponent implements OnInit, OnDestroy {
  initialized = false;
  positions: MoneyPressPositionViewModel[];
  closedPositions: MoneyPressPositionViewModel[];
  fetcher: any;
  totalPL: number;
  showAnalysis: boolean;
  totalMargin: number;
  closedTotalPL: number;
  showExitPrice: boolean;
  showSupport: boolean;

  constructor(private etradeService: ETradeService, private title: Title) {}

  ngOnInit(): void {
    this.fetchData();
    this.fetcher = interval(15000).subscribe((val) => {
      this.fetchData();
    });
  }

  fetchData() {
    this.etradeService.getPositions().subscribe((stocks) => {
      this.positions = stocks.filter((x) => x.open);
      this.totalPL = this.positions.reduce((sum, current) => sum + current.totalProfitLoss, 0);
      this.totalMargin = this.positions.reduce((sum, current) => sum + current.margin, 0);
      this.closedPositions = stocks.filter((x) => !x.open);
      this.closedTotalPL = this.closedPositions.reduce((sum, current) => sum + current.totalProfitLoss, 0);
      this.showExitPrice = this.positions.filter((x) => !x.currentShortSymbol).length == 0;
      this.showSupport = this.positions.filter((x) => x.supportLine > x.lastTrade).length == 0;

      var cp = new CurrencyPipe("en-US");
      var ms = new MinusSignToParens();

      this.title.setTitle('CP - ' + ms.transform(cp.transform(this.totalPL, 'USD')));
      this.initialized = true;
    });
  }

  ngOnDestroy(): void {
    this.fetcher.unsubscribe();
  }
}

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="row">
  <div class="col-md-12">
    <h3>Princess Candidates</h3>
    <kendo-grid [kendoGridBinding]="stocks" kendoGridSelectBy="id" [pageSize]="100" [pageable]="false" [sortable]="true" [groupable]="true" [reorderable]="true" [resizable]="true" [columnMenu]="{ filter: true }">
      <ng-template kendoGridToolbarTemplate>
        <input placeholder="Search for Symbol" kendoTextBox (input)="onFilter($event.target.value)" />
        <a type="button" href="/api/lists/export" target="_blank" class="btn btn-secondary" style="float: right">Export to Excel</a>
      </ng-template>

      <kendo-grid-column-group title="General Data" [columnMenu]="false">
        <kendo-grid-column class="stock" field="ticker" title="Ticker" [width]="125">
          <ng-template kendoGridCellTemplate let-dataItem>
            <!-- <div class="stock"> -->
            <div class="customer-name">{{ dataItem.ticker }}</div>
            <figure>
              <img src="https://charts2.finviz.com/chart.ashx?t={{ dataItem.ticker }}&ta=1&p=d" width="900" height="340" alt="photo" />
            </figure>
            <!-- </div> -->
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="price" title="Price" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.price | currency }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="gapAmount" title="Gap Amount" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.gapAmount | percent:'1.2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="gapDaysAgo" title="Gap Days Ago" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.gapDaysAgo }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="maxHigh" title="Max High" [hidden]='true' [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.maxHigh | currency }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="weeksUntilEarnings" title="Weeks Left" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.weeksUntilEarnings }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="smA20" [hidden]="true" title="SMA 20" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.smA20 < 0, 'text-success text-bold': dataItem.smA20 > 0 }">{{ dataItem.smA20 }}%</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="smA50" [hidden]="true" title="SMA 50" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.smA50 < 0, 'text-success text-bold': dataItem.smA50 > 0 }">{{ dataItem.smA50 }}%</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="smA200" [hidden]="true" title="SMA 200" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.smA200 < 0, 'text-success text-bold': dataItem.smA200 > 0 }">{{ dataItem.smA200 }}%</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Yahoo Analysis" [hidden]="true" [columnMenu]="false">
        <kendo-grid-column field="shortTermBull" title="Short " [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.shortTermBull == 'Bear', 'text-success text-bold': dataItem.shortTermBull == 'Bull', 'text-warning text-bold': dataItem.shortTermBull == 'Flat' }">{{ dataItem.shortTermBull }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="midTermBull" title="Mid " [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.midTermBull == 'Bear', 'text-success text-bold': dataItem.midTermBull == 'Bull', 'text-warning text-bold': dataItem.midTermBull == 'Flat' }">{{ dataItem.midTermBull }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="longTermBull" title="Long " [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.longTermBull == 'Bear', 'text-success text-bold': dataItem.longTermBull == 'Bull', 'text-warning text-bold': dataItem.longTermBull == 'Flat' }">{{ dataItem.longTermBull }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="StockInvest Analysis" [hidden]="true" [columnMenu]="false">
        <kendo-grid-column field="stockInvestScore" title="Score" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.stockInvestScore < -1, 'text-success text-bold': dataItem.stockInvestScore > 1, 'text-warning text-bold': dataItem.stockInvestScore < 1 && dataItem.stockInvestScore > -1 }">{{ dataItem.stockInvestScore }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockInvestCandidateSort" title="Candidate" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.stockInvestCandidate == 'Sell' || dataItem.stockInvestCandidate == 'Strong Sell', 'text-success text-bold': dataItem.stockInvestCandidate == 'Buy' || dataItem.stockInvestCandidate == 'Strong Buy', 'text-warning text-bold': dataItem.stockInvestCandidate == 'Hold' }">{{ dataItem.stockInvestCandidate }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockInvestRiskSort" title="Risk" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.stockInvestRisk == 'High', 'text-success text-bold': dataItem.stockInvestRisk == 'Low', 'text-warning text-bold': dataItem.stockInvestRisk == 'Medium' }">{{ dataItem.stockInvestRisk }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockInvestRiseFallAmount" title="Rise/Fall" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.stockInvestRiseFall == 'rise'" class="customer-name text-success">{{ dataItem.stockInvestRiseFallAmount / 100 | percent: '1.2-2' }}</div>
            <div *ngIf="dataItem.stockInvestRiseFall == 'fall'" class="customer-name text-danger">{{ dataItem.stockInvestRiseFallAmount / 100 | percent: '1.2-2' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="IBD Analysis" [columnMenu]="false">
        <kendo-grid-column field="accDist" title="Acc/Dist" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.accDistPass == 'F', 'text-success text-bold': dataItem.accDistPass == 'P', 'text-warning text-bold': dataItem.accDistPass == 'N' }">
              <strong>{{ dataItem.accDist }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="composite" title="Composite" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.compositePass == 'F', 'text-success text-bold': dataItem.compositePass == 'P', 'text-warning text-bold': dataItem.compositePass == 'N' }">
              <strong>{{ dataItem.composite }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="rsRating" title="RS" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.rsRatingPass == 'F', 'text-success text-bold': dataItem.rsRatingPass == 'P' }">
              <strong>{{ dataItem.rsRating }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="epsRating" title="EPS" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.epsRatingPass == 'F', 'text-success text-bold': dataItem.epsRatingPass == 'P', 'text-warning text-bold': dataItem.epsRatingPass == 'N' }">
              <strong>{{ dataItem.epsRating }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="smrRating" title="SMR" [hidden]="true" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.smrRatingPass == 'F', 'text-success text-bold': dataItem.smrRatingPass == 'P', 'text-warning text-bold': dataItem.smrRatingPass == 'N' }">
              <strong>{{ dataItem.smrRating }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Zacks Analysis" [hidden]='true' [columnMenu]="false">
        <kendo-grid-column field="zacksRank" title="Rank" [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.zacksRank >= 4, 'text-success text-bold': dataItem.zacksRank <= 2, 'text-warning text-bold': dataItem.zacksRank == 3 }">
              <strong>{{ dataItem.zacksRank }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksCompScore" title="Comp." [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.zacksCompScore == 'D' || dataItem.zacksCompScore == 'E' || dataItem.zacksCompScore == 'F', 'text-warning text-bold': dataItem.zacksCompScore == 'C', 'text-success text-bold': dataItem.zacksCompScore == 'A' || dataItem.zacksCompScore == 'B' }">
              <strong>{{ dataItem.zacksCompScore }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksGrowth" title="Growth" [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.zacksGrowth == 'D' || dataItem.zacksGrowth == 'E' || dataItem.zacksGrowth == 'F', 'text-warning text-bold': dataItem.zacksGrowth == 'C', 'text-success text-bold': dataItem.zacksGrowth == 'A' || dataItem.zacksGrowth == 'B' }">
              <strong>{{ dataItem.zacksGrowth }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksValue" title="Value" [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.zacksValue == 'D' || dataItem.zacksValue == 'E' || dataItem.zacksValue == 'F', 'text-warning text-bold': dataItem.zacksValue == 'C', 'text-success text-bold': dataItem.zacksValue == 'A' || dataItem.zacksValue == 'B' }">
              <strong>{{ dataItem.zacksValue }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksMomentum" title="Momentum" [hidden]="true" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.zacksMomentum == 'D' || dataItem.zacksMomentum == 'E' || dataItem.zacksMomentum == 'F', 'text-warning text-bold': dataItem.zacksMomentum == 'C', 'text-success text-bold': dataItem.zacksMomentum == 'A' || dataItem.zacksMomentum == 'B' }">
              <strong>{{ dataItem.zacksMomentum }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>
    <p>
      <span *ngFor="let stock of stocks">{{ stock.ticker }}, </span>
    </p>
  </div>
</div>

// #docregion
import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import 'moment-timezone';

@Pipe({ name: 'duration', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class DurationPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }

    let val = input.split(':');
    let hours = parseInt(val[0]);
    let min = parseInt(val[1]);
    let totalMins = hours * 60 + min;

    if (totalMins < 60) {
      return totalMins + ' minutes';
    }

    if (totalMins === 60) {
      return '1 hour';
    }

    if (hours > 1 && min === 0) {
      return hours + ' hours';
    }

    if (hours > 1 && min > 0) {
      return hours + ' hours, ' + min + ' mins';
    }

    if (hours > 0 && min > 0) {
      return hours + ' hour, ' + min + ' mins';
    }
  }
}

@Pipe({
  name: 'minusSignToParens',
})
export class MinusSignToParens implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.charAt(0) === '-' ? '(' + value.substring(1, value.length) + ')' : value;
  }
}

@Pipe({ name: 'durationClean', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class DurationCleanPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }

    let val = input.split(':');
    let hours = parseInt(val[0]);
    let min = parseInt(val[1]);
    let sec = parseInt(val[2]);

    let returnVal = '';

    if (hours === 0 && min === 0 && sec > 0) {
      return `${sec} sec`;
    }

    if (hours === 0 && min > 0) {
      return `${min}:${sec.toString().padStart(2, '0')}`;
    }

    return `${hours}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  }
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (value == null) {
      return value;
    }
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    if (value.substr(0, limit).length < value.length) {
      return `${value.substr(0, limit)}${ellipsis}`;
    } else {
      return `${value.substr(0, limit)}`;
    }
  }
}

@Pipe({ name: 'websiteUrlPipe', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class WebsiteUrlPipe implements PipeTransform {
  transform(input: string, showHttp: boolean = true): string {
    if (!input) {
      return '';
    }
    let retval = '';
    if (showHttp) {
      retval = 'http://';
    }
    return retval + input.replace('http://', '').replace('https://', '');
  }
}

@Pipe({ name: 'titlecase', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class TitleCasePipe implements PipeTransform {
  transform(input: string): string {
    return !input || input.length === 0 ? '' : input.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.substr(1).toLowerCase());
  }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

@Pipe({ name: 'LeftPadFilter', pure: false })
export class LeftPadFilter implements PipeTransform {
  transform(item: string): string {
    return (String('0').repeat(2) + item).substr(2 * -1, 2);
  }
}

@Pipe({ name: 'pluralizer', pure: false })
/** Transform to Title Case: uppercase the first letter of the words in a string.*/
export class PluralizerPipe implements PipeTransform {
  transform(input: number, unpluralValue: string, pluralValue: string): string {
    return input + ' ' + (input === 1 ? unpluralValue : pluralValue);
  }
}

@Pipe({ name: 'stripHtml', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class StripHtmlPipe implements PipeTransform {
  transform(html: any): string {
    if (!html || html.length === 0) {
      return '';
    }
    return html.replace(/<[^>]*>/g, '').replace('&nbsp;', ' ');
  }
}

@Pipe({ name: 'timezone', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class TimezonePipe implements PipeTransform {
  transform(datetime: any): string {
    let timeZone = moment.tz.guess();
    let timeZoneOffset = new Date(datetime).getTimezoneOffset();
    return moment.tz.zone(timeZone).abbr(timeZoneOffset);
  }
}

@Pipe({ name: 'camelCaseToSpaces', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(input: string): string {
    if (!input || input.length === 0) {
      return '';
    }
    let result = input.replace(/[A-Z]/g, (txt) => ' ' + txt);
    return result.length === 1 ? result[0].toUpperCase() : result[0].toUpperCase() + result.substr(1);
  }
}

@Pipe({ name: 'booleanToText', pure: false })
/** Transform nullable boolean value to text.*/
export class BooleanToTextPipe implements PipeTransform {
  transform(input: boolean | null, trueVal: string | null, falseVal: string | null): string {
    if (input == null || input === undefined) {
      return '';
    }

    if (trueVal == null || trueVal == undefined) {
      trueVal = 'True';
    }

    if (falseVal == null || falseVal == undefined) {
      falseVal = 'False';
    }

    return input ? trueVal : falseVal;
  }
}

@Pipe({ name: 'enumValue', pure: false })
/** Transform camel case values into multiple words as needed, all uppercase first letters.*/
export class EnumValuePipeTransform implements PipeTransform {
  transform(enumVal: any, theEnum: any, description: any): string {
    let result = description.get(parseInt(theEnum[enumVal]));
    return result;
  }
}

@Pipe({ name: 'noDataDisplay', pure: false })
/** Transform null / undefined / blank to No Data for display.*/
export class NoDataDisplayPipe implements PipeTransform {
  transform(input: any, result: string = 'No Data'): string {
    if (!input) {
      return result;
    }
    return input;
  }
}

declare module '@angular/forms/' {
  interface FormGroup {
    markAllControlsDirty: () => FormGroup;
  }
}

declare module '@angular/forms' {
  interface FormArray {
    markAllControlsDirty: () => FormArray;
  }
}

FormArray.prototype.markAllControlsDirty = function (): FormArray {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.markAllControlsDirty();
    } else {
      abstractControl.markAsDirty();
    }
  });
  return this;
};

FormGroup.prototype.markAllControlsDirty = function (): FormGroup {
  Object.keys(this.controls).forEach((key: string) => {
    const abstractControl = this.controls[key];

    if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
      abstractControl.markAllControlsDirty();
    } else {
      abstractControl.markAsDirty();
    }
  });
  return this;
};

export function dynamicSort(property: any) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  let propertyArray = property.split('.');

  return function (a: any, b: any) {
    //handles up to 3 levels of properties
    let aProperty = a[propertyArray[0]] == null || a[propertyArray[0]].length == 0 ? null : a[propertyArray[0]];
    let bProperty = b[propertyArray[0]] == null || b[propertyArray[0]].length == 0 ? null : b[propertyArray[0]];

    if (propertyArray.length > 1) {
      aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]] == null || a[propertyArray[0]][propertyArray[1]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]];
      bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]] == null || b[propertyArray[0]][propertyArray[1]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]];
    }
    if (propertyArray.length == 3) {
      aProperty = aProperty == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || a[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : a[propertyArray[0]][propertyArray[1]][propertyArray[2]];
      bProperty = bProperty == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]] == null || b[propertyArray[0]][propertyArray[1]][propertyArray[2]].length == 0 ? null : b[propertyArray[0]][propertyArray[1]][propertyArray[2]];
    }

    aProperty = typeof aProperty === 'string' ? aProperty.toLowerCase() : aProperty;
    bProperty = typeof bProperty === 'string' ? bProperty.toLowerCase() : bProperty;

    if (bProperty == null) {
      return 1 * sortOrder;
    }
    if (aProperty == null) {
      return -1 * sortOrder;
    } else if (aProperty < bProperty) {
      return -1 * sortOrder;
    } else if (aProperty > bProperty) {
      return 1 * sortOrder;
    } else {
      return 0;
    }
  };
}

export function onlyUniqueById(value: any, index: any, self: any) {
  return self.map((x: any) => x['id']).indexOf(value['id']) === index;
}

export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

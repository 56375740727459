import { Injectable, ViewContainerRef, TemplateRef } from '@angular/core';
import { Http } from '@angular/http';
import { Route } from '@angular/router';
import { ToastrService, Toast, ActiveToast } from 'ngx-toastr';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../_controls/confirm-modal/confirm-modal.component';

@Injectable()
export class ToasterService {
  //public RootPageVC: ViewContainerRef | undefined;

  constructor(public toastr: ToastrService, private dialogService: DialogService, private modalService: NgbModal) {}

  init(vc: ViewContainerRef): void {
    //  this.toastr.setRootViewContainerRef(vc);
  }

  error(message: string, title?: string, options?: any) {
    this.toastr.error(message, title, options);
  }

  info(message: string, title?: string, options?: any) {
    this.toastr.info(message, title, options);
  }

  success(message: string, title?: string, options?: any) {
    this.toastr.success(message, title, options);
  }

  warning(message: string, title?: string, options?: any) {
    this.toastr.warning(message, title, options);
  }

  //custom(message: string, title?: string, options?: any) {
  //     this.toastr.custom(message, title, options);
  //}

  dirtyFormDialog(): Observable<boolean> {
    return this.confirmDialog('You have unsaved changes on the form. Are you sure you want to discard them?', 'Are you sure?').pipe(
      map((result) => {
        return result;
      })
    );
  }

  confirmDialog(message: string, title: string, ok: string = 'Yes', cancel: string = 'No'): Observable<boolean> {
    let x = new Observable<boolean>((observer) => {
      const modalRef = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true });
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.ok = ok;
      modalRef.componentInstance.cancel = cancel;
      modalRef.componentInstance.singleButton = false;

      modalRef.result.then(
        (result) => {
          observer.next(true);
          observer.complete();
        },
        (reason) => {
          observer.next(false);
          observer.complete();
        }
      );
    });
    return x;
  }

  messageDialog(message: string, title: string, ok: string = 'Ok', error: boolean = false): Observable<boolean> {
    let x = new Observable<boolean>((observer) => {
      const modalRef = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true });
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.ok = ok;
      modalRef.componentInstance.singleButton = true;
      modalRef.componentInstance.showSpinner = false;
      modalRef.componentInstance.error = error;

      modalRef.result.then(
        (result) => {
          observer.next(true);
          observer.complete();
        },
        (reason) => {
          observer.next(false);
          observer.complete();
        }
      );
    });
    return x;
  }

  spinnerDialog(message: string, title: string, ok: string = 'Ok'): Observable<NgbModalRef> {
    let x = new Observable<NgbModalRef>((observer) => {
      const modalRef = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true });
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.ok = ok;
      modalRef.componentInstance.singleButton = true;
      modalRef.componentInstance.showSpinner = true;

      observer.next(modalRef);
      observer.complete();
    });
    return x;
  }
}

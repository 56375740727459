<page-load-header *ngIf="!initialized"></page-load-header>

<h3>MoneyPress Stock Check</h3>
<div *ngIf="initialized" class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="input-group mb-3">
        <input type="text" class="form-control" (keyup.enter)="checkStock()" placeholder="Stock Symbol" [(ngModel)]="symbol" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <div class="input-group-append">
          <button class="btn btn-success" type="button" (click)="checkStock()" id="button-addon2">Check</button>
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
  <page-load-header *ngIf="searching"></page-load-header>
  <div *ngIf="firstSearch && !searching" class="list-group mt-5 mb-5">
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.hasWeeklies }">
      <div class="text-center w-100">
        <h5 class="mb-1">{{ currentData.companyName }}</h5>
      </div>
    </div>
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.hasWeeklies }">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Weekly Options</h5>
        <h5 *ngIf="currentData.hasWeeklies" class="text-success"><i class="fas fa-check-circle"></i></h5>
      </div>
      <p *ngIf="currentData.hasWeeklies" class="mb-1">This Stock has weeklies.</p>
      <p *ngIf="!currentData.hasWeeklies" class="mb-1">This Stock does not have weeklies.</p>
    </div>
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.pricePassed }">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Stock Price</h5>
        <h5 *ngIf="currentData.pricePassed" class="text-success"><i class="fas fa-check-circle"></i></h5>
      </div>
      <p *ngIf="currentData.pricePassed" class="mb-1">{{ currentData.price }} is greater than 20</p>
      <p *ngIf="!currentData.pricePassed" class="mb-1">{{ currentData.price }} is less than 20</p>
    </div>
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.premiumPassed }">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Premium</h5>
        <h5 *ngIf="currentData.premiumPassed" class="text-success"><i class="fas fa-check-circle"></i></h5>
      </div>
      <p *ngIf="currentData.premiumPassed" class="mb-1">{{ currentData.last }} is greater than {{ currentData.price * 0.01 | number: '1.2' }} (1% of price)</p>
      <p *ngIf="!currentData.premiumPassed" class="mb-1">{{ currentData.last }} is less than {{ currentData.price * 0.01 | number: '1.2' }} (1% of price)</p>
    </div>
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.bidAskSpreadPassed }">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Bid/Ask Spread</h5>
        <h5 *ngIf="currentData.bidAskSpreadPassed" class="text-success"><i class="fas fa-check-circle"></i></h5>
      </div>
      <p *ngIf="currentData.bidAskSpreadPassed" class="mb-1">The spread is {{ currentData.ask - currentData.bid | number: '1.2' }} which is less than {{ currentData.bid * 0.2 | number: '1.2' }} (20% of the bid).</p>
      <p *ngIf="!currentData.bidAskSpreadPassed" class="mb-1">The spread is {{ currentData.ask - currentData.bid | number: '1.2' }} which is more than {{ currentData.bid * 0.2 | number: '1.2' }} (20% of the bid).</p>
    </div>
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.volumePassed }">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Volume</h5>
        <h5 *ngIf="currentData.volumePassed" class="text-success"><i class="fas fa-check-circle"></i></h5>
      </div>
      <p *ngIf="currentData.volumePassed" class="mb-1">The volume is {{ currentData.volume }} which is more than 2,000,000.</p>
      <p *ngIf="!currentData.volumePassed" class="mb-1">The volume is {{ currentData.volume }} which is less than 2,000,000.</p>
    </div>
    <div class="list-group-item list-group-item-action" [ngClass]="{ 'dance-with-devil': !currentData.earningsPassed }">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Weeks until Earnings</h5>
        <h5 *ngIf="currentData.earningsPassed" class="text-success"><i class="fas fa-check-circle"></i></h5>
      </div>
      <p class="mb-1">There are {{ currentData.weeksUntilEarnings }} weeks left until earnings</p>
    </div>
    <div class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Acc/Dist Rating</h5>
      </div>
      <p class="mb-1">{{currentData.accDist}}</p>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { ETradeTransactionViewModel, MoneyPressPositionViewModel } from 'src/app/_models/generatedModels';
import { ETradeService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-trade-history',
  templateUrl: 'trade-history.component.html',
})
export class TradeHistoryComponent implements OnInit, OnDestroy {
  initialized = false;
  position: MoneyPressPositionViewModel;

  private routeSubscription: Subscription;
  symbol: number;
  longOrders: ETradeTransactionViewModel[];
  shortOrders: ETradeTransactionViewModel[];
  longExits: ETradeTransactionViewModel[];
  fetcher: any;
  shortExits: ETradeTransactionViewModel[];
  constructor(private route: ActivatedRoute, private etradeService: ETradeService, private title: Title) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe((param) => {
      this.symbol = parseInt(param.get('id'));
      this.fetchData();
      this.fetcher = interval(5000).subscribe((val) => {
        this.fetchData();
      });
    });
  }

  fetchData() {
    this.etradeService.getPosition(this.symbol).subscribe((position) => {
      this.position = position;
      this.title.setTitle(position.symbol + ' - Trade Journal');
      this.longOrders = position.orders.filter((x) => x.transactionType == 'BUY_OPEN');
      this.longExits = position.orders.filter((x) => x.transactionType == 'SELL_CLOSE');
      this.shortExits = position.orders.filter((x) => x.transactionType == 'BUY_CLOSE');
      this.shortOrders = position.orders.filter((x) => x.transactionType == 'SELL_OPEN');
      this.initialized = true;
    });
  }

  GetEarningsLong(idx: number, item: ETradeTransactionViewModel): number {
    var exit = this.LongExitPremium(idx, item.optionSymbol);
    return exit - item.premium;
  }

  GetPercentLong(idx: number, item: ETradeTransactionViewModel): number {
    var exit = this.LongExitPremium(idx, item.optionSymbol);
    return (exit - item.premium) / item.premium;
  }

  GetPLLong(idx: number, item: ETradeTransactionViewModel): number {
    var exit = this.GetEarningsLong(idx, item);
    return exit * this.position.qty * 100;
  }

  GetLongCumulative(idx: number, item: ETradeTransactionViewModel): number {
    let total = 0;

    for (let i = 0; i < idx + 1; i++) {
      var premium = this.longOrders[i].premium;
      if (this.longExits[i]) {
        var exit = this.longExits[i].premium;
        total = total + (exit - premium);
      } else {
        var exit = this.position.currentLongValue;
        total = total + (exit - premium);
      }
    }

    return total * this.position.qty * 100;
  }

  GetShortCumulative(idx: number, item: ETradeTransactionViewModel): number {
    let total = 0;

    for (let i = 0; i < idx + 1; i++) {
      var premium = this.shortOrders[i].premium;
      if (this.shortExits[i]) {
        var exit = this.shortExits[i].premium;
        total = total + (premium - exit);
      } else {
        var exit = this.position.currentShortValue;
        total = total + (premium - exit);
      }
    }

    return total * this.position.qty * 100;
  }

  GetEarningsShort(idx: number, item: ETradeTransactionViewModel): number {
    var exit = this.ShortExitPremium(idx, item.optionSymbol);
    return item.premium - exit;
  }

  GetPercentShort(idx: number, item: ETradeTransactionViewModel): number {
    var exit = this.ShortExitPremium(idx, item.optionSymbol);
    return (item.premium - exit) / item.premium;
  }

  GetPLShort(idx: number, item: ETradeTransactionViewModel): number {
    var exit = this.GetEarningsShort(idx, item);
    return exit * this.position.qty * 100;
  }

  LongExitPremium(index: number, symbol: string): number {
    var filtered = this.longExits;
    var match = filtered[index];

    if (match) {
      return match.premium;
    }
    return this.position.currentLongValue;
  }

  HasExitedLong(index: number, symbol: string): boolean {
    var filtered = this.longExits;
    var match = filtered[index];

    if (match) {
      return true;
    }
    return false;
  }

  LongExitDate(index: number, symbol: string): any {
    var filtered = this.longExits;
    var match = filtered[index];

    if (match) {
      return match.transactionDate;
    }
    return null;
  }

  ShortExitPremium(index: number, symbol: string): number {
    var filtered = this.shortExits;
    var match = filtered[index];

    if (match) {
      return match.premium;
    }
    return this.position.currentShortValue;
  }

  HasExitedShort(index: number, symbol: string): boolean {
    var filtered = this.shortExits;
    var match = filtered[index];
    if (match) {
      return true;
    }
    return false;
  }

  ShortExitDate(index: number, symbol: string): any {
    var filtered = this.shortExits;
    var match = filtered[index];

    if (match) {
      return match.transactionDate;
    }
    return null;
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.fetcher.unsubscribe();
  }
}

<header id="header" class="u-header">
  <div *ngIf="futures" class="tray">
    <span class="futures" *ngIf="futures.futures">FUTURES</span>
    <span class="indices" id="indices">
      <a href="https://us.etrade.com/etx/mkt/quotes?symbol=DJIND#/snapshot" data-symbol="DJIND" class="undefined">
        <span class="index-symbol">DOW</span>
        <span class="last nav-hidden-md">{{ futures.dow | number: '1.2-2' }}</span>
        <span class="change" [ngClass]="{ loss: futures.dowChange < 0, gain: futures.dowChange > 0 }">
          <span *ngIf="futures.dowChange < 0" aria-label="loss">▼</span>
          <span *ngIf="futures.dowChange > 0" aria-label="loss">▲</span>
          <span class="nav-hidden-md">{{ futures.dowChange | number: '1.2-2' }}</span>
        </span>
        <span class="percentChange">({{ futures.dowChangePercent / 100.0 | percent: '1.2' }})</span>
      </a>

      <a href="https://us.etrade.com/etx/mkt/quotes?symbol=COMP.IDX#/snapshot" data-symbol="COMP.IDX" class="undefined">
        <span class="index-symbol">NASDAQ</span>
        <span class="last nav-hidden-md">{{ futures.nasdaq | number: '1.2-2' }}</span>
        <span class="change" [ngClass]="{ loss: futures.nasdaqChange < 0, gain: futures.nasdaqChange > 0 }">
          <span *ngIf="futures.nasdaqChange < 0" aria-label="loss">▼</span>
          <span *ngIf="futures.nasdaqChange > 0" aria-label="loss">▲</span>
          <span class="nav-hidden-md">{{ futures.nasdaqChange | number: '1.2-2' }}</span>
        </span>
        <span class="percentChange">({{ futures.nasdaqChangePercent / 100.0 | percent: '1.2' }})</span>
      </a>

      <a href="https://us.etrade.com/etx/mkt/quotes?symbol=SPX#/snapshot" data-symbol="SPX" class="undefined">
        <span class="index-symbol">S&amp;P&nbsp;500</span>
        <span class="last nav-hidden-md">{{ futures.sP500 | number: '1.2-2' }}</span>
        <span class="change" [ngClass]="{ loss: futures.sP500Change < 0, gain: futures.sP500Change > 0 }">
          <span *ngIf="futures.sP500Change < 0" aria-label="loss">▼</span>
          <span *ngIf="futures.sP500Change > 0" aria-label="loss">▲</span>
          <span class="nav-hidden-md">{{ futures.sP500Change | number: '1.2-2' }}</span>
        </span>
        <span class="percentChange">({{ futures.sP500ChangePercent / 100.0 | percent: '1.2' }})</span>
      </a></span
    >
    <span class="market-time" id="market-time">as of {{ futures.time | date: 'shortTime' }} ET</span>
  </div>
  <div class="u-header__section" style="background-color: #241056">
    <!-- Topbar -->
    <div class="container-fluid u-header__hide-content pt-2">
      <div class="d-flex align-items-center">
        <!-- Language -->

        <!-- End Language -->

        <div class="ml-auto">
          <!-- Links -->
          <div class="d-d-inline-block ml-sm-auto">
            <ul class="list-inline mb-0">
              <li class="list-inline-item mr-3">
                <a class="u-header__navbar-link" href="/etrade/initiate-etrade-oauth">OAuth </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Topbar -->

    <div id="logoAndNav" class="container-fluid">
      <!-- Nav -->
      <nav class="ml-5 js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal">
        <!-- Logo -->
        <a class="navbar-brand u-header__navbar-brand u-header__navbar-brand-center" style="color: white" [routerLink]="['/']" aria-label="Front">
          <h3>ChrisPicks</h3>
        </a>
        <!-- End Logo -->

        <!-- Responsive Toggle Button -->

        <!-- End Responsive Toggle Button -->

        <!-- Navigation -->

        <!-- End Navigation -->
      </nav>
      <!-- End Nav -->
    </div>
  </div>
</header>

<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="container-fluid">
  <div class="row">
    <div class="col">
      <h3>
        <a class="text-primary" [routerLink]="['../../']"><u>My Positions</u> </a> -> {{ position.symbol }} (<span [ngClass]="{ 'text-danger text-bold': position.lastTrade < position.previousClose, 'text-success text-bold': position.lastTrade > position.previousClose }">{{ position.lastTrade | currency }}</span
        >)
      </h3>
    </div>
  </div>
  <div class="card p-0">
    <div class="card-header text-center p-0"><h4>Long Chain</h4></div>
    <div class="card-body p-0">
      <table class="table table-hover">
        <thead class="bg-light">
          <tr>
            <th>Expiration Date</th>
            <th>Strike</th>
            <th>Delta</th>
          </tr>
        </thead>
        <tbody>
          <tr [ngClass]="{ 'bg-success': order.strike == position.currentLongStrike && order.expiryDate == position.currentLongExpiraton, 'bg-light': order.expiryDate == position.currentLongExpiraton && order.strike != position.currentLongStrike }" *ngFor="let order of chains; let idx = index">
            <td *ngIf="order.expiryDate == position.currentLongExpiraton">
              <strong>{{ order.expiryDate | date: 'shortDate' }}</strong>
            </td>
            <td *ngIf="order.expiryDate != position.currentLongExpiraton">{{ order.expiryDate | date: 'shortDate' }}</td>

            <td *ngIf="order.expiryDate == position.currentLongExpiraton">
              <strong>{{ order.strike }}</strong>
            </td>
            <td *ngIf="order.expiryDate != position.currentLongExpiraton">{{ order.strike }}</td>

            <td *ngIf="order.expiryDate == position.currentLongExpiraton">
              <strong>{{ order.delta | number: '1.3-3' }}</strong>
            </td>
            <td *ngIf="order.expiryDate != position.currentLongExpiraton">{{ order.delta | number: '1.3-3' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

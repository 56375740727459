import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SettingsProvider } from '../../_services/settingsProvider.service';

@Component({
  selector: 'main-layout',
  templateUrl: './mainLayout.component.html'
})
export class MainLayout {

  constructor(public settings: SettingsProvider) {

  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBDDateViewModel, StockViewModel } from 'src/app/_models/generatedModels';
import { DataService } from 'src/app/_services/generatedServices';
import { GroupDescriptor, process } from '@progress/kendo-data-query';
import { Title } from '@angular/platform-browser';
import { interval } from 'rxjs';
declare var UAParser: any;

@Component({
  selector: 'bxl-ibd-gmi',
  templateUrl: 'ibd-gmi.component.html',
})
export class IbdGmiComponent implements OnInit, OnDestroy {
  initialized: boolean;
  query: string;
  fetcher: any;
  currentBeakpoint: string;
  mobile : boolean;
  dates: IBDDateViewModel[];
  dates2: IBDDateViewModel[];
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


  constructor(private dataService: DataService, private title: Title) {}
  public aggregates: any[] = [
    { field: "gc", aggregate: "sum" },
  ];
  public groups: GroupDescriptor[] = [{ field: 'monthNum', dir:'desc' }];

  ngOnInit(): void {
    this.mobile = this.isMobileDevice();
    this.title.setTitle('Market Status');
    this.fetchData();
    this.fetcher = interval(15000).subscribe((val) => {
      this.fetchData();
    });
  }

  isMobileDevice(): boolean {
    let parser = new UAParser();
    let result = parser.getResult();



    if (result.os.name == 'Mac OS' || result.os.name == 'Windows'){
      console.log('not mobile');
      return false;
    }

    return true;
  }

  public fetchData() {
    this.dataService.getIBDGMI().subscribe((stocks) => {
      this.dates = stocks;
      this.dates2 = stocks;
      console.log(this.dates);
      this.initialized = true;
    });
  }

  public onFilter(inputValue: string): void {
    this.dates2 = process(this.dates, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ticker',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    //this.dataBinding.skip = 0;
  }

  ngOnDestroy(): void {
    this.fetcher.unsubscribe();
  }
}

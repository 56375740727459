<app-nav-menu [isCompanyPage]="false"></app-nav-menu>
<div  class="container-fluid bg-light" style="min-height: 800px;">
  <div class="container-fluid">
    <ul class="nav nav-tabs mb-3 companyNav">
      <li class="nav-item">
        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/moneypress']"><i class="fas fa-sack-dollar"></i> MoneyPress</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/crowbar']"><i class="fas fa-screwdriver"></i> Crowbar</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/princess']"><i class="fas fa-crown"></i> Princess</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/watch-list']"><i class="fas fa-heart"></i> Watch List</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/ibd-gmi']"><i class="fas fa-chart-line"></i> Market Status</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/my-positions']"><i class="fas fa-chart-line"></i> My Positions</a>
      </li>

    </ul>
    <router-outlet></router-outlet>
  </div>
</div>

<!-- ========== FOOTER ========== -->
<footer class="container text-center space-2">
  <!-- Logo -->
  <!-- End Logo -->

  <!-- List -->
  <ul class="list-inline list-group-flush list-group-borderless">
    <li class="list-inline-item px-2">
      <a class="list-group-item-action" [routerLink]="['/terms']">Terms of Service</a>
    </li>
    <li class="list-inline-item px-2">
      <a class="list-group-item-action" [routerLink]="['/privacy']">Privacy Policy</a>
    </li>
  </ul>
  <!-- End List -->

  <!-- Copyright -->
  <p class="small text-muted mb-0">© 2020 {{settings.applicationName}} Inc.</p>
  <p class="small text-muted mb-0">{{settings.configuration.version}}</p>
  <p class="small text-muted mb-0">{{settings.configuration.clientIP}}</p>
  <!-- End Copyright -->
</footer>
<!-- ========== END FOOTER ========== -->
<!-- ========== END FOOTER ========== -->

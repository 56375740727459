<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized && mobile">
  <div class="row">
    <div class="col-7"><h4>Market Status</h4></div>
    <div class="col-5 text-right"></div>
  </div>

  <div *ngFor="let dataItem of dates" class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-6 mb-2">
          <strong>{{ dataItem.date | date: 'shortDate' }} </strong>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 small">
          <hr class="m-0" />
          <strong>Index Overview </strong>
        </div>
        <div class="col-3 mb-2">
          <span class="grid-label">DOW</span>
          <div class="customer-name" [ngClass]="dataItem.dow">{{ dataItem.dow | noDataDisplay: '-' }} &nbsp;</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.dowChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.dowChangePercent < 0, 'text-success': dataItem.dowChangePercent > 0 }">{{ dataItem.dowChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-3 mb-2">
          <span class="grid-label">NAS</span>
          <div class="customer-name" [ngClass]="dataItem.nasdaq">{{ dataItem.nasdaq | noDataDisplay: '-' }}&nbsp;</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.nasdaqChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.nasdaqChangePercent < 0, 'text-success': dataItem.nasdaqChangePercent > 0 }">{{ dataItem.nasdaqChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-3 mb-2">
          <span class="grid-label">S&P</span>
          <div class="customer-name" [ngClass]="dataItem.sP500">{{ dataItem.sP500 | noDataDisplay: '-' }}&nbsp;</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.sP500Change | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.sP500ChangePercent < 0, 'text-success': dataItem.sP500ChangePercent > 0 }">{{ dataItem.sP500ChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-3 mb-2">
          <span class="grid-label">IWM</span>
          <div class="customer-name" [ngClass]="dataItem.iwm">{{ dataItem.iwm | noDataDisplay: '-' }}&nbsp;</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.iwmChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.iwmChangePercent < 0, 'text-success': dataItem.iwmChangePercent > 0 }">{{ dataItem.iwmChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 small">
          <hr class="m-0" />
          <strong>Highs & Lows</strong>
        </div>

        <div class="col-3 mb-2">
          <span class="grid-label">Lows</span>
          <div class="customer-name">{{ dataItem.totalLows }}</div>
        </div>

        <div class="col-3 mb-2">
          <span class="grid-label">Highs</span>
          <div class="customer-name">{{ dataItem.totalHighs }}</div>
        </div>
        <div class="col-3 mb-2">
          <span class="grid-label">Diff</span>
          <div class="customer-name">{{ dataItem.gcDiff }}</div>
        </div>

        <div class="col-3 mb-2">
          <span class="grid-label">GC</span>
          <div class="customer-name d-block cursor-pointer">{{ dataItem.gc }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 small">
          <hr class="m-0" />
          <strong>Advance & Decline</strong>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Diff</span>
          <div class="customer-name" [ngClass]="{ 'text-danger': dataItem.advancing - dataItem.declining < 0, 'text-success': dataItem.advancing - dataItem.declining > 0 }">{{ dataItem.advancing - dataItem.declining | number }}</div>
          <div class="customer-name d-block small cursor-pointer">{{ dataItem.advancing / dataItem.declining | number: '1.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Advance</span>
          <div class="customer-name">{{ dataItem.advancing | number }}</div>
          <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.advancing - dataItem.declining < 0, 'text-success': dataItem.advancing - dataItem.declining > 0 }">{{ dataItem.advancing / (dataItem.advancing + dataItem.declining) | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Decline</span>
          <div class="customer-name">{{ dataItem.declining | number }}</div>
          <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.advancing - dataItem.declining < 0, 'text-success': dataItem.advancing - dataItem.declining > 0 }">{{ dataItem.declining / (dataItem.advancing + dataItem.declining) | percent: '0.1-2' }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 small">
          <hr class="m-0" />
          <strong>SMA 50</strong>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Diff</span>
          <div class="customer-name" [ngClass]="{ 'text-danger': dataItem.aboveSMA50 - dataItem.belowSMA50 < 0, 'text-success': dataItem.aboveSMA50 - dataItem.belowSMA50 > 0 }">{{ dataItem.aboveSMA50 - dataItem.belowSMA50 | number }}</div>
          <div class="customer-name d-block small cursor-pointer">{{ dataItem.aboveSMA50 / dataItem.belowSMA50 | number: '1.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Above</span>
          <div class="customer-name">{{ dataItem.aboveSMA50 | number }}</div>
          <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.aboveSMA50 - dataItem.belowSMA50 < 0, 'text-success': dataItem.aboveSMA50 - dataItem.belowSMA50 > 0 }">{{ dataItem.aboveSMA50 / (dataItem.aboveSMA50 + dataItem.belowSMA50) | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Below</span>
          <div class="customer-name">{{ dataItem.belowSMA50 | number }}</div>
          <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.aboveSMA50 - dataItem.belowSMA50 < 0, 'text-success': dataItem.aboveSMA50 - dataItem.belowSMA50 > 0 }">{{ dataItem.belowSMA50 / (dataItem.aboveSMA50 + dataItem.belowSMA50) | percent: '0.1-2' }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 small">
          <hr class="m-0" />
          <strong>Sector Performance</strong>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Materials</span>
          <div class="customer-name" [ngClass]="dataItem.xlb">{{ dataItem.xlb }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlbChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlbChangePercent < 0, 'text-success': dataItem.xlbChangePercent > 0 }">{{ dataItem.xlbChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Comms</span>
          <div class="customer-name" [ngClass]="dataItem.xlc">{{ dataItem.xlc }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlcChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlcChangePercent < 0, 'text-success': dataItem.xlcChangePercent > 0 }">{{ dataItem.xlcChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Energy</span>
          <div class="customer-name" [ngClass]="dataItem.xle">{{ dataItem.xle }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xleChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xleChangePercent < 0, 'text-success': dataItem.xleChangePercent > 0 }">{{ dataItem.xleChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Financial</span>
          <div class="customer-name" [ngClass]="dataItem.xlf">{{ dataItem.xlf }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlfChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlfChangePercent < 0, 'text-success': dataItem.xlfChangePercent > 0 }">{{ dataItem.xlfChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Indstrials</span>
          <div class="customer-name" [ngClass]="dataItem.xli">{{ dataItem.xli }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xliChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xliChangePercent < 0, 'text-success': dataItem.xliChangePercent > 0 }">{{ dataItem.xliChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Tech</span>
          <div class="customer-name" [ngClass]="dataItem.xlk">{{ dataItem.xlk }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlkChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlkChangePercent < 0, 'text-success': dataItem.xlkChangePercent > 0 }">{{ dataItem.xlkChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Con Stpls</span>
          <div class="customer-name" [ngClass]="dataItem.xlp">{{ dataItem.xlp }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlpChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlpChangePercent < 0, 'text-success': dataItem.xlpChangePercent > 0 }">{{ dataItem.xlpChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Rl Estate</span>
          <div class="customer-name" [ngClass]="dataItem.xlre">{{ dataItem.xlre }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlreChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlreChangePercent < 0, 'text-success': dataItem.xlreChangePercent > 0 }">{{ dataItem.xlreChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Utilities</span>
          <div class="customer-name" [ngClass]="dataItem.xlu">{{ dataItem.xlu }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xluChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xluChangePercent < 0, 'text-success': dataItem.xluChangePercent > 0 }">{{ dataItem.xluChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Healthcare</span>
          <div class="customer-name" [ngClass]="dataItem.xlv">{{ dataItem.xlv }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlvChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlvChangePercent < 0, 'text-success': dataItem.xlvChangePercent > 0 }">{{ dataItem.xlvChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
        <div class="col-4 mb-2">
          <span class="grid-label">Con Discrtry</span>
          <div class="customer-name" [ngClass]="dataItem.xly">{{ dataItem.xly }}</div>
          <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlyChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlyChangePercent < 0, 'text-success': dataItem.xlyChangePercent > 0 }">{{ dataItem.xlyChangePercent / 100 | percent: '0.1-2' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="initialized && !mobile" class="row">
  <div class="col-md-12">
    <h3>Market Status</h3>
    <kendo-grid [kendoGridBinding]="dates" kendoGridSelectBy="id" [pageSize]="90" [pageable]="false" [sortable]="true" [groupable]="true" [group]="groups" [reorderable]="true" [resizable]="true" [columnMenu]="{ filter: true }">
      <kendo-grid-column-group title="Index Overview" [columnMenu]="false">
        <kendo-grid-column [columnMenu]="false" field="date" title="Date" [width]="90">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.date | date: 'shortDate' }}</div>
            <div class="customer-name d-block small">{{ dataItem.dayOfWeek }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="monthNum" [hidden]="true" title="Month" [width]="125" filter="text">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.month }}</div>
          </ng-template>
          <ng-template kendoGridGroupHeaderTemplate let-value="value"> Month: {{ months[value - 1] }} </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="dow" title="Dow" [width]="80" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.dow">{{ dataItem.dow }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.dowChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.dowChangePercent < 0, 'text-success': dataItem.dowChangePercent > 0 }">{{ dataItem.dowChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="nasdaq" title="Nasdaq" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.nasdaq">{{ dataItem.nasdaq }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.nasdaqChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.nasdaqChangePercent < 0, 'text-success': dataItem.nasdaqChangePercent > 0 }">{{ dataItem.nasdaqChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="sP500" title="S&P 500" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.sP500">{{ dataItem.sP500 }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.sP500Change | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.sP500ChangePercent < 0, 'text-success': dataItem.sP500ChangePercent > 0 }">{{ dataItem.sP500ChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="iWM" title="IWM" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.iwm">{{ dataItem.iwm }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.iwmChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.iwmChangePercent < 0, 'text-success': dataItem.iwmChangePercent > 0 }">{{ dataItem.iwmChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Gambler's Count" [columnMenu]="false">
        <kendo-grid-column [columnMenu]="false" [hidden]="true" field="date" title="GC">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.gc }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="gc" title="Diff">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.gcDiff }}</div>
            <div class="customer-name d-block small cursor-pointer">GC: {{ dataItem.gc }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Highs">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.totalHighs }}</div>
            <div class="customer-name d-block small cursor-pointer">&nbsp;</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Lows">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.totalLows }}</div>
            <div class="customer-name d-block small cursor-pointer">&nbsp;</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Advance/Decline" [columnMenu]="false">
        <kendo-grid-column [columnMenu]="false" field="advancing" title="Diff">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger': dataItem.advancing - dataItem.declining < 0, 'text-success': dataItem.advancing - dataItem.declining > 0 }">{{ dataItem.advancing - dataItem.declining | number }}</div>
            <div class="customer-name d-block small cursor-pointer">{{ dataItem.advancing / dataItem.declining | number: '1.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="advancing" title="Advance">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.advancing | number }}</div>
            <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.advancing - dataItem.declining < 0, 'text-success': dataItem.advancing - dataItem.declining > 0 }">{{ dataItem.advancing / (dataItem.advancing + dataItem.declining) | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="declining" title="Decline">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.declining | number }}</div>
            <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.advancing - dataItem.declining < 0, 'text-success': dataItem.advancing - dataItem.declining > 0 }">{{ dataItem.declining / (dataItem.advancing + dataItem.declining) | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Above/Below SMA 50" [columnMenu]="false">
        <kendo-grid-column [columnMenu]="false" field="advancing" title="Diff">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger': dataItem.aboveSMA50 - dataItem.belowSMA50 < 0, 'text-success': dataItem.aboveSMA50 - dataItem.belowSMA50 > 0 }">{{ dataItem.aboveSMA50 - dataItem.belowSMA50 | number }}</div>
            <div class="customer-name d-block small cursor-pointer">{{ dataItem.aboveSMA50 / dataItem.belowSMA50 | number: '1.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="aboveSMA50" title="Above">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.aboveSMA50 | number }}</div>
            <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.aboveSMA50 - dataItem.belowSMA50 < 0, 'text-success': dataItem.aboveSMA50 - dataItem.belowSMA50 > 0 }">{{ dataItem.aboveSMA50 / (dataItem.aboveSMA50 + dataItem.belowSMA50) | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="belowSMA50" title="Below">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.belowSMA50 | number }}</div>
            <div class="d-block small" [ngClass]="{ 'text-danger': dataItem.aboveSMA50 - dataItem.belowSMA50 < 0, 'text-success': dataItem.aboveSMA50 - dataItem.belowSMA50 > 0 }">{{ dataItem.belowSMA50 / (dataItem.aboveSMA50 + dataItem.belowSMA50) | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Canary ETFs" [columnMenu]="false">
        <kendo-grid-column [columnMenu]="false" field="date" title="Mat" [tooltip]="'Materials'">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Materials (XLB)">Mats</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlb">{{ dataItem.xlb }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlbChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlbChangePercent < 0, 'text-success': dataItem.xlbChangePercent > 0 }">{{ dataItem.xlbChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Comm">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Communication (XLC)">Comm</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlc">{{ dataItem.xlc }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlcChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlcChangePercent < 0, 'text-success': dataItem.xlcChangePercent > 0 }">{{ dataItem.xlcChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Energy">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Energy (XLE)">Energy</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xle">{{ dataItem.xle }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xleChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xleChangePercent < 0, 'text-success': dataItem.xleChangePercent > 0 }">{{ dataItem.xleChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Fin">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Financial (XLF)">Fin</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlf">{{ dataItem.xlf }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlfChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlfChangePercent < 0, 'text-success': dataItem.xlfChangePercent > 0 }">{{ dataItem.xlfChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Indus">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Industries (XLI)">Indus</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xli">{{ dataItem.xli }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xliChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xliChangePercent < 0, 'text-success': dataItem.xliChangePercent > 0 }">{{ dataItem.xliChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Tech">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Technology (XLK)">Tech</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlk">{{ dataItem.xlk }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlkChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlkChangePercent < 0, 'text-success': dataItem.xlkChangePercent > 0 }">{{ dataItem.xlkChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Con Stpls">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Consumer Staples (XLP)">Con Stpls</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlp">{{ dataItem.xlp }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlpChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlpChangePercent < 0, 'text-success': dataItem.xlpChangePercent > 0 }">{{ dataItem.xlpChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Rl Este">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Real Estate (XLRE)">Rl Estate</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlre">{{ dataItem.xlre }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlreChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlreChangePercent < 0, 'text-success': dataItem.xlreChangePercent > 0 }">{{ dataItem.xlreChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Util">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Utilities (XLU)">Util</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlu">{{ dataItem.xlu }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xluChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xluChangePercent < 0, 'text-success': dataItem.xluChangePercent > 0 }">{{ dataItem.xluChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Hlth">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Healthcare (XLV)">Hlth</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xlv">{{ dataItem.xlv }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlvChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlvChangePercent < 0, 'text-success': dataItem.xlvChangePercent > 0 }">{{ dataItem.xlvChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [columnMenu]="false" field="date" title="Con Discrtry">
          <ng-template kendoGridHeaderTemplate let-dataItem>
            <div class="small" title="Consumer Discretionary (XLY)">Con Discrtry</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="dataItem.xly">{{ dataItem.xly }}</div>
            <div class="customer-name d-block small cursor-pointer" [title]="dataItem.xlyChange | number: '1.2-2'" [ngClass]="{ 'text-danger': dataItem.xlyChangePercent < 0, 'text-success': dataItem.xlyChangePercent > 0 }">{{ dataItem.xlyChangePercent / 100 | percent: '0.1-2' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GroupDescriptor, process } from '@progress/kendo-data-query';
import { interval } from 'rxjs';
import { StockViewModel } from 'src/app/_models/generatedModels';
import { DataService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-watch-list',
  templateUrl: 'watch-list.component.html',
})
export class WatchListComponent implements OnInit, OnDestroy {
  initialized: boolean;
  query: string;
  fetcher: any;
  currentBeakpoint: string;
  symbolToAdd: string;
  stocks: StockViewModel[];
  stocks2: StockViewModel[];

  constructor(private dataService: DataService, private title: Title) {}

  public groups: GroupDescriptor[] = [{ field: 'sector' }];

  ngOnInit(): void {
    this.fetchData();
    this.title.setTitle('Watch List');
    this.fetcher = interval(15000).subscribe((val) => {
      this.fetchData();
    });
  }

  public fetchData() {
    this.dataService.getWishList().subscribe((stocks) => {
      this.stocks = stocks;
      this.stocks2 = stocks;
      this.initialized = true;
    });
  }

  public addSymbol() {
    this.dataService.addToWishList(this.symbolToAdd).subscribe((result) => {
      this.fetchData();
      this.symbolToAdd = '';
    });
  }

  public onFilter(inputValue: string): void {
    this.stocks = process(this.stocks2, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ticker',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    //this.dataBinding.skip = 0;
  }

  ngOnDestroy(): void {
    this.fetcher.unsubscribe();
  }
}

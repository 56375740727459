import { Component, OnInit } from '@angular/core';
import { StockViewModel } from 'src/app/_models/generatedModels';
import { DataService } from 'src/app/_services/generatedServices';
import { process } from '@progress/kendo-data-query';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'bxl-princess-candidates',
  templateUrl: 'princess-candidates.component.html',
})
export class PrincessCandidatesComponent implements OnInit {
  initialized: boolean;
  query: string;
  currentBeakpoint: string;
  stocks: StockViewModel[];
  stocks2: StockViewModel[];

  constructor(private dataService: DataService, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle('Princess Candidates');
    this.dataService.getPrincessStocks().subscribe((stocks) => {
      this.stocks = stocks;
      this.stocks2 = stocks;
      this.initialized = true;
    });
  }

  public onFilter(inputValue: string): void {
    this.stocks = process(this.stocks2, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ticker',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    //this.dataBinding.skip = 0;
  }
}

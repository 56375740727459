import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AppComponent } from './app.component';
import { MainLayout } from './_shared/layouts/mainLayout.component';
import { NavMenuComponent } from './_shared/nav-menu/nav-menu.component';
import { HomeComponent } from './homepage/home.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';
import { ClickOutsideModule } from 'ng-click-outside';
import { InlineSVGModule } from 'ng-inline-svg';
import { SettingsProvider } from './_services/settingsProvider.service';
import { ToasterService } from './_services/toaster.service';
import { ToastrModule } from 'ngx-toastr';
import { EditorModule } from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TitleCasePipe, CamelCaseToSpacesPipe, BooleanToTextPipe, NoDataDisplayPipe, DurationPipe, PluralizerPipe, TruncatePipe, DurationCleanPipe, StripHtmlPipe, EnumValuePipeTransform, TimezonePipe, LeftPadFilter, WebsiteUrlPipe, SafeHtmlPipe, MinusSignToParens } from './_helpers/extensions.module';

import { UploadModule } from '@progress/kendo-angular-upload';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AngularResizedEventModule } from 'angular-resize-event';
import { TimeAgoPipe } from 'time-ago-pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConfirmModalComponent } from './_controls/confirm-modal/confirm-modal.component';
import { BlobStorageService } from './_services/blob-storage.service';
import { BLOB_STORAGE_TOKEN, IAzureStorage } from './_services/azureStorage';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import {TooltipModule} from 'ng2-tooltip-directive';
import { PageLoadHeaderComponent } from './_helpers/pageLoadHeader.component';
import { DataService, ETradeService } from './_services/generatedServices';
import { GridModule } from '@progress/kendo-angular-grid';
import { CrowbarCandidatesComponent } from './homepage/crowbar/crowbar-candidates.component';
import { IbdGmiComponent } from './homepage/ibdgmi/ibd-gmi.component';
import { MyPositionsComponent } from './homepage/my-positions/my-positions.component';
import { TradeHistoryComponent } from './homepage/my-positions/trade-history/trade-history.component';
import { TradeDetailsComponent } from './homepage/my-positions/details/trade-details.component';
import { ShortChainsComponent } from './homepage/my-positions/short-chains/short-chains.component';
import { SymbolDataComponent } from './homepage/symbol-data/symbol-data.component';
import { MpCheckupComponent } from './homepage/checkup/mp-checkup.component';
import { PrincessCandidatesComponent } from './homepage/princess/princess-candidates.component';
import { PositionHistoryComponent } from './homepage/my-positions/position-history/position-history.component';
import { WatchListComponent } from './homepage/watch-list/watch-list.component';


declare var AzureStorage: IAzureStorage;

@NgModule({
  declarations: [AppComponent,
    MainLayout,
    NavMenuComponent,
    HomeComponent,
    ShortChainsComponent,
    SafeHtmlPipe,
    WebsiteUrlPipe,
    EnumValuePipeTransform,
    TitleCasePipe,
    MpCheckupComponent,
    PageLoadHeaderComponent,
    DurationPipe,
    DurationCleanPipe,
    TruncatePipe,
    TradeHistoryComponent,
    PositionHistoryComponent,
    WatchListComponent,
    StripHtmlPipe,
    PluralizerPipe,
    MinusSignToParens,
    PrincessCandidatesComponent,
    ConfirmModalComponent,
    CamelCaseToSpacesPipe,
    TimeAgoPipe,
    TradeDetailsComponent,
    CrowbarCandidatesComponent,
    BooleanToTextPipe,
    NoDataDisplayPipe,
    SymbolDataComponent,
    TimezonePipe,
    MyPositionsComponent,
    IbdGmiComponent,
    LeftPadFilter,
  ],
    entryComponents: [
       ConfirmModalComponent,

    ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    TooltipModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    FormsModule,
    ImageCropperModule,
    NgxEmojiPickerModule.forRoot(),
    ClipboardModule,
    InlineSVGModule.forRoot(),
    DialogsModule,
    DateInputsModule,
    InputsModule,
    DropDownsModule,
    AngularResizedEventModule,
    UploadModule,
    EditorModule,
    NgbModule,

    ToastrModule.forRoot(),
    Angular2PromiseButtonModule.forRoot(),
    RouterModule.forRoot([
      { path: '',
        component: MainLayout,
        children: [
          { path: '', redirectTo: 'moneypress', pathMatch: 'full' },
          { path: 'moneypress', component: HomeComponent },
          { path: 'moneypress/checkup', component: MpCheckupComponent },
          { path: 'crowbar', component: CrowbarCandidatesComponent },
          { path: 'princess', component: PrincessCandidatesComponent },
          { path: 'ibd-gmi', component: IbdGmiComponent },
          { path: 'watch-list', component: WatchListComponent },
          { path: 'my-positions', component: MyPositionsComponent },
          { path: 'my-positions/:id', component: TradeHistoryComponent },
          { path: 'my-positions/:id/details', component: TradeDetailsComponent },
          { path: 'my-positions/:id/history', component: PositionHistoryComponent },
          { path: 'my-positions/:id/short-chains', component: ShortChainsComponent },
          { path: 'symbol/:id', component: SymbolDataComponent },

        ]
      },

      // { path: '', component: HomeComponent, pathMatch: 'full' },
      // { path: 'counter', component: CounterComponent },
      // { path: 'fetch-data', component: FetchDataComponent },
    ],
    { onSameUrlNavigation: 'reload' }
    ),
    GridModule
  ],
  providers: [
    SettingsProvider,
    NgbActiveModal,
    DataService,
    ETradeService,
    BlobStorageService,
     ToasterService,
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: APP_INITIALIZER, 'useFactory': init, 'deps': [SettingsProvider, HttpClient], 'multi': true }
    ],
  exports: [
      TitleCasePipe,
      DurationPipe,
      EnumValuePipeTransform,
      TruncatePipe,
      WebsiteUrlPipe,
      SafeHtmlPipe,
      MinusSignToParens,
      TimezonePipe,
      DurationCleanPipe,
      TimeAgoPipe,
      PluralizerPipe,
      StripHtmlPipe,
      LeftPadFilter,
      CamelCaseToSpacesPipe,
      BooleanToTextPipe,
      NoDataDisplayPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function init(settingsProvider: SettingsProvider, http: HttpClient) {
  return () => settingsProvider.loadConfig(http);
}

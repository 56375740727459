/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pageLoadHeader.component";
var styles_PageLoadHeaderComponent = [];
var RenderType_PageLoadHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageLoadHeaderComponent, data: {} });
export { RenderType_PageLoadHeaderComponent as RenderType_PageLoadHeaderComponent };
export function View_PageLoadHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "col-md-12 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "k-icon k-i-loading text-primary"], ["style", "font-size:60px;"]], null, null, null, null, null))], null, null); }
export function View_PageLoadHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-load-header", [], null, null, null, View_PageLoadHeaderComponent_0, RenderType_PageLoadHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.PageLoadHeaderComponent, [], null, null)], null, null); }
var PageLoadHeaderComponentNgFactory = i0.ɵccf("page-load-header", i1.PageLoadHeaderComponent, View_PageLoadHeaderComponent_Host_0, {}, {}, []);
export { PageLoadHeaderComponentNgFactory as PageLoadHeaderComponentNgFactory };

import { Component, Input, OnInit, AfterViewInit, HostListener, ElementRef, ViewChild, Renderer2, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/_services/toaster.service';
import { interval, Subscription } from 'rxjs';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { ETradeService } from 'src/app/_services/generatedServices';
import { FuturesViewModel } from 'src/app/_models/generatedModels';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isCompanyPage: boolean;
  isExpanded = false;
  closeResult: string;
  fetcher: any;
  verificationEmailSent = false;
  queryRouteSubscription: Subscription;

  @ViewChild('navBar', { static: true }) navBar;
  @ViewChild('hamburger', { static: true }) hamburger;
  futures: FuturesViewModel;
  // public user: UserProfileViewModel;

  constructor(public settings: SettingsProvider, private etradeService: ETradeService, private route: ActivatedRoute, private ref: ChangeDetectorRef, private modalService: NgbModal, private renderer: Renderer2, private element: ElementRef, private toaster: ToasterService, private router: Router) {}

  @HostListener('click', ['$event']) click(event) {
    let dropdown = this.element.nativeElement.querySelectorAll('a');
    dropdown.forEach((element) => {
      if (element && element.contains(event.target)) {
        this.renderer.removeClass(this.navBar.nativeElement, 'show');
        this.renderer.addClass(this.hamburger.nativeElement, 'collapsed');
        this.renderer.setAttribute(this.hamburger.nativeElement, 'aria-expanded', 'false');
      }
    });
  }

  ngOnInit(): void {
    this.fetchData();
    this.fetcher = interval(10000).subscribe((val) => {
      this.fetchData();
    });
  }

  fetchData() {
    this.etradeService.getFutures().subscribe((futures) => {
      this.futures = futures;
    });
  }

  ngAfterViewInit() {
    (<any>$)('.js-mega-menu').HSMegaMenu({
      event: 'hover',
      pageContainer: $('.container'),
      breakpoint: 767.98,
      hideTimeOut: 0,
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnDestroy(): void {
    this.fetcher.unsubscribe();
  }
}

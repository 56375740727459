import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { StockViewModel } from 'src/app/_models/generatedModels';
import { ETradeService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-symbol-data',
  templateUrl: 'symbol-data.component.html',
})
export class SymbolDataComponent implements OnInit, OnDestroy {
  initialized = false;
  private routeSubscription: Subscription;
  symbol: string;
  fetcher: Subscription;
  stock: StockViewModel;
  constructor(private route: ActivatedRoute, private etradeService: ETradeService) {}
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.fetcher.unsubscribe();
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe((param) => {
      this.symbol = param.get('id');
      this.fetchData();
      this.fetcher = interval(5000).subscribe((val) => {
        this.fetchData();
      });
    });
  }

  fetchData() {
    this.etradeService.getSymbolData(this.symbol).subscribe((stock) => {
      this.stock = stock;
      this.initialized = true;
    });
  }
}

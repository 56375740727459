import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';
import { StockViewModel, IBDDateViewModel, MoneyPressPositionViewModel, MoneyPressPositionHistoryViewModel, MoneyPressCandidateResult, FuturesViewModel, LongRollChoiceViewModel } from '../_models/generatedModels';
import { SettingsProvider } from './settingsProvider.service';

@Injectable()
export class DataService {
  private baseUrl;

  constructor(private http: HttpClient, settings: SettingsProvider) {
    this.baseUrl = settings.configuration.baseUrl;
  }

  getMoneyPressStocks(): Observable<StockViewModel[]> {
       return this.http.get<StockViewModel[]>(`${this.baseUrl}/lists/moneypress`);
   }

  getWishList(): Observable<StockViewModel[]> {
       return this.http.get<StockViewModel[]>(`${this.baseUrl}/lists/wish-list`);
   }

  addToWishList(ticker: string): Observable<StockViewModel[]> {
       return this.http.post<StockViewModel[]>(`${this.baseUrl}/lists/wish-list/add?ticker=${ticker}`, null)
            .pipe(map((result: StockViewModel[]) => result));
  }

  getCrowbarStocks(): Observable<StockViewModel[]> {
       return this.http.get<StockViewModel[]>(`${this.baseUrl}/lists/crowbar`);
   }

  getPrincessStocks(): Observable<StockViewModel[]> {
       return this.http.get<StockViewModel[]>(`${this.baseUrl}/lists/princess`);
   }

  getIBDGMI(): Observable<IBDDateViewModel[]> {
       return this.http.get<IBDDateViewModel[]>(`${this.baseUrl}/lists/gmi`);
   }

  export(): Observable<IBDDateViewModel[]> {
       return this.http.get<IBDDateViewModel[]>(`${this.baseUrl}/lists/export`);
   }

}
@Injectable()
export class ETradeService {
  private baseUrl;

  constructor(private http: HttpClient, settings: SettingsProvider) {
    this.baseUrl = settings.configuration.baseUrl;
  }

  initiateGarminOAuth(): Observable<any> {
       return this.http.get<any>(`${this.baseUrl}/~/etrade/initiate-etrade-oauth`);
   }

  completeGarminOauth(oauth_verifier: string): Observable<any> {
       return this.http.get<any>(`${this.baseUrl}/~/etrade/complete-oauth?oauth_verifier=${oauth_verifier}`);
   }

  getPositions(): Observable<MoneyPressPositionViewModel[]> {
       return this.http.get<MoneyPressPositionViewModel[]>(`${this.baseUrl}/lists/mypostions`);
   }

  getPosition(id: number): Observable<MoneyPressPositionViewModel> {
       return this.http.get<MoneyPressPositionViewModel>(`${this.baseUrl}/lists/mypostions/${id}`);
   }

  getHistory(id: number): Observable<MoneyPressPositionHistoryViewModel[]> {
       return this.http.get<MoneyPressPositionHistoryViewModel[]>(`${this.baseUrl}/lists/mypostions/${id}/history`);
   }

  checkMoneyPressCandidate(symbol: string): Observable<MoneyPressCandidateResult> {
       return this.http.get<MoneyPressCandidateResult>(`${this.baseUrl}/check-candidate?symbol=${symbol}`);
   }

  test(symbol: string): Observable<MoneyPressPositionViewModel> {
       return this.http.get<MoneyPressPositionViewModel>(`${this.baseUrl}/test?symbol=${symbol}`);
   }

  addPosition(symbol: string): Observable<MoneyPressPositionViewModel> {
       return this.http.get<MoneyPressPositionViewModel>(`${this.baseUrl}/add-position?symbol=${symbol}`);
   }

  getFutures(): Observable<FuturesViewModel> {
       return this.http.get<FuturesViewModel>(`${this.baseUrl}/futures`);
   }

  getOptionChains(positionId: number): Observable<LongRollChoiceViewModel[]> {
       return this.http.get<LongRollChoiceViewModel[]>(`${this.baseUrl}/lists/mypostions/${positionId}/chains`);
   }

  getSymbolData(symbol: string): Observable<StockViewModel> {
       return this.http.get<StockViewModel>(`${this.baseUrl}/symbol/${symbol}`);
   }

  getOptionChainsForShort(positionId: number): Observable<LongRollChoiceViewModel[]> {
       return this.http.get<LongRollChoiceViewModel[]>(`${this.baseUrl}/lists/mypostions/${positionId}/chains/short`);
   }

}

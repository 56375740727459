<page-load-header *ngIf="!initialized"></page-load-header>

<div *ngIf="initialized" class="row">
  <div class="col-md-12">
    <div class="d-md-none">
      <div class="row">
        <div class="col-7"><h4>My Positions</h4></div>
        <div class="col-5 text-right" [ngClass]="{ 'text-danger text-bold': totalPL < 0, 'text-success text-bold': totalPL > 0 }">
          <strong>{{ totalPL | currency | minusSignToParens }}</strong>
        </div>
      </div>

      <div *ngFor="let position of positions" class="card mb-3">
        <div class="card-body" [ngClass]="{ 'in-the-money': position.shortIntrinsicValue > 0 && position.action != 'DWTD', 'dance-with-devil': position.action == 'DWTD' }">
          <div class="row">
            <div class="col-6 mb-2">
              <strong>{{ position.symbol }} </strong>
            </div>

            <div class="col-6 mb-2">
              <span [ngClass]="{ 'text-danger text-bold': position.lastTrade < position.previousClose, 'text-success text-bold': position.lastTrade > position.previousClose }">{{ (position.lastTrade - position.previousClose) / position.previousClose | percent: '1.2' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mb-2">
              <span class="grid-label">Strike/Price</span>
              {{ position.currentShortStrike | currency }} / <span [ngClass]="{ 'text-danger text-bold': position.lastTrade < position.previousClose, 'text-success text-bold': position.lastTrade > position.previousClose }">{{ position.lastTrade | currency }}</span>
            </div>
            <div class="col-6 mb-2">
              <span class="grid-label">Strikes ITM</span>
              <div class="customer-name">{{ position.strikesInTheMoney }}/{{ position.longStrikesInTheMoney }} {{ position.action }}</div>
            </div>
            <div class="col-6 mb-2">
              <span class="grid-label">Short Delta</span>
              <div *ngIf="position.currentShortSymbol" [routerLink]="[position.id, 'short-chains']" class="customer-name" [ngClass]="{ 'text-danger text-bold': position.longDelta < position.shortDelta, 'text-success text-bold': position.longDelta > position.shortDelta }">{{ position.shortDelta | number: '1.2-2' }}</div>
              <div *ngIf="!position.currentShortSymbol" [routerLink]="[position.id, 'short-chains']" class="customer-name">-</div>
            </div>
            <div class="col-6 mb-2">
              <span class="grid-label">Long Delta</span>
              <div class="customer-name" [routerLink]="[position.id, 'details']" [ngClass]="{ 'text-danger text-bold': position.longDelta > -0.25, 'text-warning text-bold': position.longDelta < -0.25 && position.longDelta > -0.3, 'text-success text-bold': position.longDelta < -0.3 }">{{ position.longDelta | number: '1.2-2' }}</div>
            </div>
            <ng-container *ngIf="!position.currentShortSymbol || position.lastTrade <= position.supportLine">
              <div class="col-6 mb-2">
                <span class="grid-label">Exit Price</span>
                <div *ngIf="position.exitPrice" class="customer-name">
                  <span [ngClass]="{ 'text-danger text-bold': position.lastTrade > position.exitPrice, 'text-success text-bold': position.lastTrade < position.exitPrice }"> {{ position.exitPrice | currency }} </span>
                  <span [ngClass]="{ 'text-warning text-bold': position.lastTrade / position.exitPrice >= 0.99 && position.lastTrade / position.exitPrice < 1, 'text-success text-bold': position.lastTrade / position.exitPrice < 0.99, 'text-danger text-bold': position.lastTrade / position.exitPrice > 1 }"> ({{ position.lastTrade / position.exitPrice | percent: '1.1' }}) </span>
                </div>
                <div *ngIf="!position.exitPrice">N/A</div>
              </div>
              <div class="col-6 mb-2">
                <span class="grid-label">Support Broken</span>
                <span *ngIf="position.lastTrade <= position.supportLine"> Yes</span>
                <span *ngIf="position.lastTrade > position.supportLine"> No</span>
              </div>
            </ng-container>

            <div class="col-6 mb-2">
              <span class="grid-label">Short Expiration</span>
              <span *ngIf="position.currentShortSymbol" [ngClass]="{ 'text-warning text-bold': position.shortExpiresDays < 4 }">{{ position.currentShortExpiraton | date: 'shortDate' }}</span>
              <span *ngIf="!position.currentShortSymbol">-</span>
            </div>
            <div class="col-6 mb-2">
              <span class="grid-label">Long Expiration</span>
              <span [ngClass]="{ 'text-danger text-bold': position.longExpiresDays <= 30, 'text-warning text-bold': position.longExpiresDays > 30 && position.longExpiresDays <= 45 }">{{ position.currentLongExpiraton | date: 'shortDate' }}</span>
            </div>
            <div class="col-6 mb-2">
              <span class="grid-label">Earnings</span>
              <div *ngIf="position.weeksUntilEarnings < 0" class="customer-name">N/A</div>
              <div *ngIf="position.weeksUntilEarnings > 0 && position.daysUntilEarnings > 7" class="customer-name">{{ position.weeksUntilEarnings }} Weeks</div>
              <div *ngIf="position.daysUntilEarnings > 0 && position.daysUntilEarnings <= 7" class="customer-name text-danger">{{ position.daysUntilEarnings }} Days</div>
            </div>
            <div class="col-6 mb-2">
              <span class="grid-label">Total P/L</span>
              <div class="customer-name" [ngClass]="{ 'text-danger text-bold': position.totalProfitLoss < 0, 'text-success text-bold': position.totalProfitLoss > 0 }">{{ position.totalProfitLoss | currency | minusSignToParens }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <kendo-grid class="d-none d-md-block" [kendoGridBinding]="positions" kendoGridSelectBy="id" [pageSize]="100" [pageable]="false" [sortable]="true" [groupable]="true" [reorderable]="true" [resizable]="true" [columnMenu]="{ filter: true }">
      <ng-template kendoGridToolbarTemplate>
        <div class="row">
          <div class="col"><h3>My Positions</h3></div>
          <div class="col text-right">
            <div class="custom-control custom-switch">
              <input class="custom-control-input" [(ngModel)]="showAnalysis" id="customSwitch1" type="checkbox" />
              <label class="custom-control-label" for="customSwitch1"><span class="d-block">Show Analysis</span></label>
            </div>
          </div>
        </div>
      </ng-template>

      <kendo-grid-column-group title="General Data" [columnMenu]="false">
        <kendo-grid-column class="no-padding" field="symbol" title="Symbol" [width]="125">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="whole-cell" [ngClass]="{ 'in-the-money': dataItem.shortIntrinsicValue > 0 && dataItem.action != 'DWTD', 'dance-with-devil': dataItem.action == 'DWTD' }">
              <div class="stock">
                <div class="customer-name">
                  <a [routerLink]="['/symbol', dataItem.symbol]">{{ dataItem.symbol }}</a>
                </div>
                <figure>
                  <img src="https://charts2.finviz.com/chart.ashx?t={{ dataItem.symbol }}&ta=1&p=d" width="900" height="340" alt="photo" />
                </figure>
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="openDate" title="Open Date" [hidden]="true" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.openDate | date: 'shortDate' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="symbolData.sector" title="Sector" [hidden]="true" [width]="105" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.symbolData.sector }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="weeksUntilEarnings" title="Earnings" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.weeksUntilEarnings < 0" class="customer-name">N/A</div>
            <div *ngIf="dataItem.weeksUntilEarnings > 0 && dataItem.daysUntilEarnings > 7" class="customer-name" [ngClass]="{ 'text-warning text-bold': dataItem.weeksUntilEarnings <= 3 && dataItem.weeksUntilEarnings > 1, 'text-danger text-bold': dataItem.weeksUntilEarnings <= 1 }">{{ dataItem.weeksUntilEarnings }} Weeks</div>
            <div *ngIf="dataItem.daysUntilEarnings > 0 && dataItem.daysUntilEarnings <= 7" class="customer-name text-danger">{{ dataItem.daysUntilEarnings }} Days</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="contracts" title="Qty" [hidden]="showAnalysis" [width]="65" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.qty }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="margin" title="Margin" [hidden]="showAnalysis" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.margin | currency }}</div>
          </ng-template>
          <ng-template kendoGridFooterTemplate let-aggregates let-field="field">
            <div class="customer-name">
              <strong>{{ totalMargin | currency | minusSignToParens }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="lastTrade" title="Strike/Price" [hidden]="showAnalysis" [width]="180" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">
              {{ dataItem.currentShortStrike | currency }} / <span [ngClass]="{ 'text-danger text-bold': dataItem.lastTrade < dataItem.previousClose, 'text-success text-bold': dataItem.lastTrade > dataItem.previousClose }">{{ dataItem.lastTrade | currency }} </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="lastTrade" title="% change" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">
              <span [ngClass]="{ 'text-danger text-bold': dataItem.lastTrade < dataItem.previousClose, 'text-success text-bold': dataItem.lastTrade > dataItem.previousClose }">{{ (dataItem.lastTrade - dataItem.previousClose) / dataItem.previousClose | percent: '1.2' }}</span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="exitPrice" title="Exit Price" [hidden]="showExitPrice || showAnalysis" [width]="120" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.exitPrice" class="customer-name">
              <span [ngClass]="{ 'text-danger text-bold': dataItem.lastTrade > dataItem.exitPrice, 'text-success text-bold': dataItem.lastTrade < dataItem.exitPrice }"> {{ dataItem.exitPrice | currency }} </span>
              <span [ngClass]="{ 'text-warning text-bold': dataItem.lastTrade / dataItem.exitPrice >= 0.99 && dataItem.lastTrade / dataItem.exitPrice < 1, 'text-success text-bold': dataItem.lastTrade / dataItem.exitPrice < 0.99, 'text-danger text-bold': dataItem.lastTrade / dataItem.exitPrice > 1 }"> ({{ dataItem.lastTrade / dataItem.exitPrice | percent: '1.1' }}) </span>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="isBelowSupport" title="Support Broken" [hidden]="showSupport || showAnalysis" [width]="120" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.lastTrade <= dataItem.supportLine" class="customer-name">Yes</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="strikesInTheMoney" title="Strikes ITM" [hidden]="showAnalysis" [width]="120" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.shortIntrinsicValue > 0 || dataItem.longIntrinsicValue > 0 || dataItem.action != 'Nothing'" class="customer-name">{{ dataItem.strikesInTheMoney }}/{{ dataItem.longStrikesInTheMoney }} {{ dataItem.action }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="longDelta" title="Long Delta" [width]="90" [hidden]="showAnalysis" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.longDelta > -0.25, 'text-warning text-bold': dataItem.longDelta < -0.25 && dataItem.longDelta > -0.3, 'text-success text-bold': dataItem.longDelta < -0.3 }">{{ dataItem.longDelta | number: '1.2-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="shortDelta" title="Short Delta" [width]="90" [hidden]="showAnalysis" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.currentShortSymbol" class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.longDelta < dataItem.shortDelta, 'text-success text-bold': dataItem.longDelta > dataItem.shortDelta }">{{ dataItem.shortDelta | number: '1.2-2' }}</div>
            <div *ngIf="!dataItem.currentShortSymbol" class="customer-name">-</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="shortTheta" title="Short Theta" [width]="90" [hidden]="true" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.shortTheta | number: '1.2-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="currentShortExpiraton" title="Short Exp" [width]="85" [hidden]="showAnalysis" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">
              <span *ngIf="dataItem.currentShortSymbol">
                <a class="text-underline" [ngClass]="{ 'text-warning text-bold': dataItem.shortExpiresDays < 4 }" [routerLink]="[dataItem.id, 'short-chains']"
                  ><u> {{ dataItem.currentShortExpiraton | date: 'shortDate' }} </u></a
                >
              </span>
              <span *ngIf="!dataItem.currentShortSymbol">
                <a class="text-underline" [ngClass]="{ 'text-warning text-bold': dataItem.shortExpiresDays < 4 }" [routerLink]="[dataItem.id, 'short-chains']"><u>-</u></a></span
              >
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="currentLongExpiraton" title="Long Exp" [width]="85" [hidden]="showAnalysis" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">
              <a class="text-underline" [ngClass]="{ 'text-danger text-bold': dataItem.longExpiresDays <= 30, 'text-warning text-bold': dataItem.longExpiresDays > 30 && dataItem.longExpiresDays <= 45 }" [routerLink]="[dataItem.id, 'details']"
                ><u>{{ dataItem.currentLongExpiraton | date: 'shortDate' }}</u></a
              >
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="longProfitLoss" title="Long P/L" [hidden]="true" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.longProfitLoss < 0, 'text-success text-bold': dataItem.longProfitLoss > 0 }">{{ dataItem.longProfitLoss | currency | minusSignToParens }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="shortProfitLoss" title="Short P/L" [hidden]="true" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.shortProfitLoss < 0, 'text-success text-bold': dataItem.shortProfitLoss > 0 }">{{ dataItem.shortProfitLoss | currency | minusSignToParens }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="totalProfitLoss" title="Total P/L" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.totalProfitLoss < 0, 'text-success text-bold': dataItem.totalProfitLoss > 0 }">{{ dataItem.totalProfitLoss | currency | minusSignToParens }}</div>
          </ng-template>
          <ng-template kendoGridFooterTemplate let-aggregates let-field="field">
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': totalPL < 0, 'text-success text-bold': totalPL > 0 }">
              <strong>{{ totalPL | currency | minusSignToParens }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Actions" [width]="125" [hidden]="showAnalysis" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name text-center">
              <a class="text-secondary text-underline" [routerLink]="[dataItem.id]"><u>Journal</u></a>
              <a class="text-secondary text-underline ml-2" [routerLink]="[dataItem.id, 'history']"><u>History</u></a>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Technical Analysis" [hidden]="!showAnalysis" [columnMenu]="false">
        <kendo-grid-column field="symbolData.signalGenerated" title="MACD" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.signalGenerated, 'text-success text-bold': !dataItem.symbolData.signalGenerated }">{{ dataItem.symbolData.signalGenerated | booleanToText: 'Sell':'Buy' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="StockInvest Analysis" [hidden]="!showAnalysis" [columnMenu]="false">
        <kendo-grid-column field="stockInvestScore" title="Score" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.stockInvestScore < -1, 'text-success text-bold': dataItem.symbolData.stockInvestScore > 1, 'text-warning text-bold': dataItem.symbolData.stockInvestScore < 1 && dataItem.symbolData.stockInvestScore > -1 }">{{ dataItem.symbolData.stockInvestScore | number: '1.2-2' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockInvestCandidate" title="Candidate" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.stockInvestCandidate == 'Sell' || dataItem.symbolData.stockInvestCandidate == 'Strong Sell', 'text-success text-bold': dataItem.symbolData.stockInvestCandidate == 'Buy' || dataItem.symbolData.stockInvestCandidate == 'Strong Buy', 'text-warning text-bold': dataItem.symbolData.stockInvestCandidate == 'Hold' }">{{ dataItem.symbolData.stockInvestCandidate }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockInvestRisk" title="Risk" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.stockInvestRisk == 'High', 'text-success text-bold': dataItem.symbolData.stockInvestRisk == 'Low', 'text-warning text-bold': dataItem.symbolData.stockInvestRisk == 'Medium' }">{{ dataItem.symbolData.stockInvestRisk }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="stockInvestRiseFallAmount" title="Rise/Fall" [width]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.symbolData.stockInvestRiseFall == 'rise'" class="customer-name text-success">{{ dataItem.symbolData.stockInvestRiseFallAmount / 100 | percent: '1.2-2' }}</div>
            <div *ngIf="dataItem.symbolData.stockInvestRiseFall == 'fall'" class="customer-name text-danger">{{ dataItem.symbolData.stockInvestRiseFallAmount / 100 | percent: '1.2-2' }}</div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="IBD Analysis" [hidden]="!showAnalysis" [columnMenu]="false">
        <kendo-grid-column field="accDist" title="Acc/Dist" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.accDistPass == 'F', 'text-success text-bold': dataItem.symbolData.accDistPass == 'P', 'text-warning text-bold': dataItem.symbolData.accDistPass == 'N' }">
              <strong>{{ dataItem.symbolData.accDist }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="composite" title="Comp" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.compositePass == 'F', 'text-success text-bold': dataItem.symbolData.compositePass == 'P', 'text-warning text-bold': dataItem.symbolData.compositePass == 'N' }">
              <strong>{{ dataItem.symbolData.composite }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="rsRating" title="RS" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.rsRatingPass == 'F', 'text-success text-bold': dataItem.symbolData.rsRatingPass == 'P' }">
              <strong>{{ dataItem.symbolData.rsRating }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="epsRating" title="EPS" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.epsRatingPass == 'F', 'text-success text-bold': dataItem.symbolData.epsRatingPass == 'P', 'text-warning text-bold': dataItem.symbolData.epsRatingPass == 'N' }">
              <strong>{{ dataItem.symbolData.epsRating }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="smrRating" title="SMR" [hidden]="true" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.smrRatingPass == 'F', 'text-success text-bold': dataItem.symbolData.smrRatingPass == 'P', 'text-warning text-bold': dataItem.symbolData.smrRatingPass == 'N' }">
              <strong>{{ dataItem.symbolData.smrRating }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Zacks Analysis" [hidden]="!showAnalysis" [columnMenu]="false">
        <kendo-grid-column field="zacksRank" title="Rank" [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.zacksRank >= 4, 'text-success text-bold': dataItem.symbolData.zacksRank <= 2, 'text-warning text-bold': dataItem.symbolData.zacksRank == 3 }">
              <strong>{{ dataItem.symbolData.zacksRank }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksCompScore" title="Comp." [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.zacksCompScore == 'D' || dataItem.symbolData.zacksCompScore == 'E' || dataItem.symbolData.zacksCompScore == 'F', 'text-warning text-bold': dataItem.symbolData.zacksCompScore == 'C', 'text-success text-bold': dataItem.symbolData.zacksCompScore == 'A' || dataItem.symbolData.zacksCompScore == 'B' }">
              <strong>{{ dataItem.symbolData.zacksCompScore }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksGrowth" title="Growth" [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.zacksGrowth == 'D' || dataItem.symbolData.zacksGrowth == 'E' || dataItem.symbolData.zacksGrowth == 'F', 'text-warning text-bold': dataItem.symbolData.zacksGrowth == 'C', 'text-success text-bold': dataItem.symbolData.zacksGrowth == 'A' || dataItem.symbolData.zacksGrowth == 'B' }">
              <strong>{{ dataItem.symbolData.zacksGrowth }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksValue" title="Value" [hidden]="false" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.zacksValue == 'D' || dataItem.symbolData.zacksValue == 'E' || dataItem.symbolData.zacksValue == 'F', 'text-warning text-bold': dataItem.symbolData.zacksValue == 'C', 'text-success text-bold': dataItem.symbolData.zacksValue == 'A' || dataItem.symbolData.zacksValue == 'B' }">
              <strong>{{ dataItem.symbolData.zacksValue }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="zacksMomentum" title="Momentum" [hidden]="true" [width]="90" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.symbolData.zacksMomentum == 'D' || dataItem.symbolData.zacksMomentum == 'E' || dataItem.symbolData.zacksMomentum == 'F', 'text-warning text-bold': dataItem.symbolData.zacksMomentum == 'C', 'text-success text-bold': dataItem.symbolData.zacksMomentum == 'A' || dataItem.symbolData.zacksMomentum == 'B' }">
              <strong>{{ dataItem.symbolData.zacksMomentum }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>
  </div>
</div>

<div *ngIf="initialized" class="row">
  <div class="col-md-12">
    <kendo-grid class="d-none d-md-block" [kendoGridBinding]="closedPositions" kendoGridSelectBy="id" [pageSize]="100" [pageable]="false" [sortable]="true" [groupable]="false" [reorderable]="true" [resizable]="true" [columnMenu]="{ filter: true }">
      <ng-template kendoGridToolbarTemplate>
        <h3>Closed Positions</h3>
      </ng-template>

      <kendo-grid-column-group title="General Data" [columnMenu]="false">
        <kendo-grid-column class="stock" field="symbol" title="Symbol" [width]="125">
          <ng-template kendoGridCellTemplate let-dataItem>
            <!-- <div class="stock"> -->
            <div class="customer-name">{{ dataItem.symbol }}</div>
            <figure>
              <img src="https://charts2.finviz.com/chart.ashx?t={{ dataItem.symbol }}&ta=1&p=d" width="900" height="340" alt="photo" />
            </figure>
            <!-- </div> -->
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="weeksUntilEarnings" title="Earnings" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.weeksUntilEarnings < 0" class="customer-name">N/A</div>
            <div *ngIf="dataItem.weeksUntilEarnings > 0" class="customer-name" [ngClass]="{ 'text-warning text-bold': dataItem.weeksUntilEarnings <= 3 && dataItem.weeksUntilEarnings > 1, 'text-danger text-bold': dataItem.weeksUntilEarnings <= 1 }">{{ dataItem.weeksUntilEarnings }} Weeks</div>
            <div *ngIf="dataItem.daysUntilEarnings > 0 && dataItem.daysUntilEarnings <= 7" class="customer-name text-danger">{{ dataItem.daysUntilEarnings }} Days</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="openDate" title="Open Date" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.openDate | date: 'shortDate' }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="closeDate" title="Close Date" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.closeDate | date: 'shortDate' }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="contracts" title="Contracts" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.qty }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="margin" title="Margin" [width]="85" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.margin | currency }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="longProfitLoss" [hidden]="true" title="Long P/L" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.longProfitLoss < 0, 'text-success text-bold': dataItem.longProfitLoss > 0 }">{{ dataItem.longProfitLoss | currency | minusSignToParens }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="shortProfitLoss" [hidden]="true" title="Short P/L" [width]="100" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.shortProfitLoss < 0, 'text-success text-bold': dataItem.shortProfitLoss > 0 }">{{ dataItem.shortProfitLoss | currency | minusSignToParens }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="totalProfitLoss" title="Total P/L" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': dataItem.totalProfitLoss < 0, 'text-success text-bold': dataItem.totalProfitLoss > 0 }">{{ dataItem.totalProfitLoss | currency | minusSignToParens }}</div>
          </ng-template>
          <ng-template kendoGridFooterTemplate let-aggregates let-field="field">
            <div class="customer-name" [ngClass]="{ 'text-danger text-bold': closedTotalPL < 0, 'text-success text-bold': closedTotalPL > 0 }">
              <strong>{{ closedTotalPL | currency | minusSignToParens }}</strong>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Journal" [width]="125" filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name text-center">
              <a class="text-secondary text-underline" [routerLink]="[dataItem.id]"><u>View</u></a>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>
  </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { GroupDescriptor, process } from '@progress/kendo-data-query';
import { SettingsProvider } from '../_services/settingsProvider.service';
import { ResizedEvent } from 'angular-resize-event';
import { DataService } from '../_services/generatedServices';
import { StockViewModel } from '../_models/generatedModels';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { stat } from 'fs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  //@ViewChild(DataBindingDirective, {null, true}) dataBinding: DataBindingDirective;

  initialized: boolean;
  query: string;
  currentBeakpoint: string;
  stocks: StockViewModel[];
  stocks2: StockViewModel[];

  constructor(private dataService: DataService, private title: Title) {}

  public groups: GroupDescriptor[] = [{ field: 'sector' }];

  ngOnInit(): void {
    this.title.setTitle("MonePress Candidates");
    this.dataService.getMoneyPressStocks().subscribe((stocks) => {
      this.stocks = stocks.filter((x) => x.allCriteriaMet);
      this.stocks2 = stocks.filter((x) => x.allCriteriaMet);
      this.initialized = true;
    });
  }

  public onFilter(inputValue: string): void {
    this.stocks = process(this.stocks2, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ticker',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    //this.dataBinding.skip = 0;
  }
}

import { tap } from 'rxjs/operators';
import * as rg4js from 'raygun4js';
import { environment } from 'src/environments/environment';
var SettingsProvider = /** @class */ (function () {
    function SettingsProvider() {
    }
    SettingsProvider.prototype.loadConfig = function (http) {
        var _this = this;
        console.log('loading config');
        return this.getJSON(http)
            .pipe(tap(function (env) {
            _this.config = env;
            window.Stripe.setPublishableKey(_this.stripePublishableKey);
            if (environment.production) {
                rg4js('apiKey', _this.config.raygunClientApiKey);
                rg4js('setVersion', _this.config.version);
                rg4js('enableCrashReporting', false);
                rg4js('enablePulse', true);
                rg4js('options', {
                    debugMode: !environment.production,
                });
            }
            if (!environment.production) {
                rg4js('disableAutoBreadcrumbsConsole');
            }
        }))
            .toPromise();
    };
    SettingsProvider.prototype.getJSON = function (http) {
        return http.get('/api/settings/client');
    };
    Object.defineProperty(SettingsProvider.prototype, "configuration", {
        get: function () {
            return this.config;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "clientIP", {
        get: function () {
            return this.config.clientIP;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "backendUrl", {
        get: function () {
            return this.config.backendUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "applicationName", {
        get: function () {
            return this.config.applicationName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "siteUrl", {
        get: function () {
            return this.config.siteUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "office365ClientId", {
        get: function () {
            return this.config.office365ClientId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "stripePublishableKey", {
        get: function () {
            return this.config.stripePublishableKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "stripeClientKey", {
        get: function () {
            return this.config.stripeClientKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "linkedInClientKey", {
        get: function () {
            return this.config.linkedInClientKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsProvider.prototype, "authTokenName", {
        get: function () {
            if (this.applicationName === 'StreamifyATL') {
                return 'streamifyatl-currentUser';
            }
            else {
                return 'StockTrader-currentUser';
            }
        },
        enumerable: true,
        configurable: true
    });
    return SettingsProvider;
}());
export { SettingsProvider };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../_helpers/pageLoadHeader.component.ngfactory";
import * as i2 from "../../_helpers/pageLoadHeader.component";
import * as i3 from "@angular/common";
import * as i4 from "./symbol-data.component";
import * as i5 from "@angular/router";
import * as i6 from "../../_services/generatedServices";
var styles_SymbolDataComponent = [];
var RenderType_SymbolDataComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SymbolDataComponent, data: {} });
export { RenderType_SymbolDataComponent as RenderType_SymbolDataComponent };
function View_SymbolDataComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "page-load-header", [], null, null, null, i1.View_PageLoadHeaderComponent_0, i1.RenderType_PageLoadHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PageLoadHeaderComponent, [], null, null)], null, null); }
function View_SymbolDataComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "stock-invest"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stock.stockInvestText; _ck(_v, 1, 0, currVal_0); }); }
export function View_SymbolDataComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SymbolDataComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Component"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SymbolDataComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.initialized; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.initialized; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SymbolDataComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bxl-symbol-data", [], null, null, null, View_SymbolDataComponent_0, RenderType_SymbolDataComponent)), i0.ɵdid(1, 245760, null, 0, i4.SymbolDataComponent, [i5.ActivatedRoute, i6.ETradeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SymbolDataComponentNgFactory = i0.ɵccf("bxl-symbol-data", i4.SymbolDataComponent, View_SymbolDataComponent_Host_0, {}, {}, []);
export { SymbolDataComponentNgFactory as SymbolDataComponentNgFactory };

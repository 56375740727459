import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MoneyPressPositionHistoryViewModel, MoneyPressPositionViewModel } from 'src/app/_models/generatedModels';
import { ETradeService } from 'src/app/_services/generatedServices';

@Component({
  selector: 'bxl-position-history',
  templateUrl: 'position-history.component.html',
})
export class PositionHistoryComponent implements OnInit {
  initialized = false;
  history: MoneyPressPositionHistoryViewModel[];

  private routeSubscription: Subscription;
  symbol: number;
  position: MoneyPressPositionViewModel;
  constructor(private route: ActivatedRoute, private etradeService: ETradeService, private title: Title) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe((param) => {
      this.symbol = parseInt(param.get('id'));
      this.etradeService.getPosition(this.symbol).subscribe((position) => {
        this.etradeService.getHistory(this.symbol).subscribe((history) => {
          this.history = history;
          this.position = position;
          this.title.setTitle(position.symbol + ' - Position History');
          this.initialized = true;
        });
      });
    });
  }
}
